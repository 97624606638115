<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
      <template slot="title">
        <div class="plugin-modal-header">
          <div class="plugin-info">
            <div class="plugin-img">
              <img :src="selectedPlugin.icon" alt="" />
            </div>
            <div class="plugin-name">
              <p>{{ selectedPlugin.name }}</p>
              <span>{{ selectedPlugin.provider }}</span>
            </div>
          </div>
        </div>
      </template>
      <div class="plugin-modal-body">
        <div>
          <p>You are about to enable Klarna Shopping.</p>
        </div>
      </div>
      <template slot="footer">
        <div>
          <el-button type="plain" @click="handleClose">Cancel</el-button>
          <el-button type="primary" @click="handleClose">Enable</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    selectedPlugin: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      githubAccessToken: "",
      repositoryAccountOwner: "",
      repositoryName: "",
    };
  },
  methods: {
    handleClose() {
      this.$emit("updateDialogVisible", false);
    },
  },
};
</script>

<style lang="scss">
@import "./assets/css/modal.scss";
</style>
