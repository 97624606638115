<template>
  <transition name="el-fade-in">
    <div ref="root">
      <div
        :class="{
          'has-text-left': isUserMessage,
          'has-text-right': !isUserMessage,
        }"
        style="position: relative"
        @mouseenter="handleShowMore(true)"
        @mouseleave="handleShowMore(false, $event)"
      >
        <template v-if="isTextMessage(message) && !isMultipleTextMessage(message)">
          <MessageBubble
            v-if="getTextFromMessage(message) && getTextFromMessage(message).length > 0"
            :message="message"
            :is-user-message="isUserMessage"
            :button-outline="buttonOutline"
            :content="getTextFromMessage(message)"
            :buttons="getButtonsFromMessage(message)"
            :has-more-than-one-agent-in-chat="hasMoreThanOneAgentInChat"
            :is-preview="isPreview"
            @sendPostback="$emit('sendPostback', $event)"
            @updatePulseRowKey="$emit('updatePulseRowKey', $event)"
          >
            <MessageAction
              style="position: absolute; top: -6px; right: -12px"
              v-if="moreButtonVisibility && showMoreButton"
              :message="message"
            />
          </MessageBubble>
        </template>
        <!--Image message-->
        <!--File message-->
        <MessageFile
          v-else-if="
            message.data.content &&
            message.data.content.length === 1 &&
            isImageOrFileOrVideoMessage(message)
          "
          :message="message"
          :is-user-message="isUserMessage"
          :button-outline="buttonOutline"
          @sendPostback="$emit('sendPostback', $event)"
          @updatePulseRowKey="$emit('updatePulseRowKey', $event)"
        >
          <MessageAction
            style="position: absolute; top: -6px; right: -12px"
            v-if="moreButtonVisibility && showMoreButton"
            :message="message"
          />
        </MessageFile>

        <MessageCarousel
          v-else-if="message.data.listStyle !== 'compact'"
          :content="getMultipleTextMessageContent(message)"
          :is-preview="isPreview"
          @sendPostback="$emit('sendPostback', $event)"
        >
          <MessageAction
            style="position: absolute; top: -6px; right: -12px"
            v-if="moreButtonVisibility && showMoreButton"
            :message="message"
          />
        </MessageCarousel>

        <MessageCompact
          v-else
          :content="getMultipleTextMessageContent(message)"
          @sendPostback="$emit('sendPostback', $event)"
        >
          <MessageAction
            style="position: absolute; top: -6px; right: -12px"
            v-if="moreButtonVisibility && showMoreButton"
            :message="message"
          />
        </MessageCompact>

        <!--Quick Replies-->
        <MessageQuickReplies
          v-if="
            getQuickRepliesFromMessage(message) && getQuickRepliesFromMessage(message).length > 0
          "
          :content="getQuickRepliesFromMessage(message)"
          :button-outline="buttonOutline"
          :is-preview="isPreview"
          @sendPostback="$emit('sendPostback', $event)"
        />

        <!-- <div
          class="opacity-fade-transition message-timestamp"
          :style="timestampStyle"
        >{{ messageTimestamp(message.Timestamp || message.date_created) }}</div>-->
      </div>
    </div>
  </transition>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

import MessageFile from "./File";
import MessageBubble from "./Bubble";
import MessageQuickReplies from "./QuickReplies";
import MessageCarousel from "./Carousel";
import MessageCompact from "./Compact";
import MessageAction from "./Action";

export default {
  name: "Message",
  components: {
    MessageFile,
    MessageBubble,
    MessageQuickReplies,
    MessageCarousel,
    MessageCompact,
    MessageAction,
  },
  props: {
    message: Object,
    hasMoreThanOneAgentInChat: {},
    isPreview: {},
  },
  data() {
    return {
      showMoreButton: false,
    };
  },
  computed: {
    ...mapGetters(["selectedChat", "isResolvedTab", "isMonitorTab"]),
    isInChat() {
      if (this.isResolvedTab || this.isMonitorTab) {
        return false;
      } else {
        const agents = _.get(this, "selectedChat.agents", []).map((email) => email.toLowerCase());
        return agents.includes(this.$store.state.profile.email?.toLowerCase());
      }
    },
    moreButtonVisibility() {
      const whatsappModule = this.$store?.state?.modules?.whatsapp;
      const cloudAPIEnabled = whatsappModule?.cloudAPIConfig?.enabled;
      const replyEnabled = whatsappModule?.cloudAPIConfig?.replyEnabled;
      // TODO: will remove this if reply available for non whatsapp
      const fromWhatsApp = this.selectedChat?.stateVariables?.source === "whatsapp";

      return cloudAPIEnabled && replyEnabled && fromWhatsApp && this.isInChat;
    },
    isUserMessage() {
      return this.message.type === "message" || this.message.type === "postback";
    },
    buttonOutline() {
      switch (this.bubbleOutlineColor) {
        case "red":
          return "danger";
        case "blue":
          return "primary";
        case "green":
          return "success";
        default:
          return "primary";
      }
    },
  },
  methods: {
    scrollIntoView(options) {
      this.$refs.root.scrollIntoView(options);
    },
    handleShowMore(show, event = null) {
      if (!show && event && event.relatedTarget?.classList?.contains("el-dropdown-menu")) {
        return;
      }
      this.showMoreButton = show;
    },
    getTextFromMessage(message) {
      const webchatMessage = _.get(message, "data.content[0].text");
      const postbackMessage = _.get(message, "data.content[0].postbackText");
      const captionMessage = _.get(message, "data.content[0].caption");
      const facebookMessage = _.get(
        message,
        "data.content[0].attachment.payload.text",
        _.get(message, "data.content[0].attachment.payload.title")
      );

      const facebookMessageDifferentFormat = _.get(
        message,
        "data.content[0].attachment.payload.elements[0].text",
        _.get(message, "data.content[0].attachment.payload.elements[0].title")
      );

      return (
        webchatMessage ||
        postbackMessage ||
        captionMessage ||
        facebookMessage ||
        facebookMessageDifferentFormat
      );
    },
    getButtonsFromMessage(message) {
      const webchatButtons = _.get(message, "data.content[0].buttons");
      const facebookButtons = _.get(message, "data.content[0].attachment.payload.buttons");
      const facebookButtonsDifferentFormat = _.get(
        message,
        "data.content[0].attachment.payload.elements[0].buttons"
      );

      const finalButtons =
        webchatButtons || facebookButtons || facebookButtonsDifferentFormat || [];
      return finalButtons;
    },
    getButtonText(button) {
      const buttonText = _.get(button, "text", button.title); // Default is 'text', else facebook is 'title'
      return buttonText;
    },
    getQuickRepliesFromMessage(message) {
      const webchatQuickReplies = _.get(message, "data.content[0].quick_replies");
      const webchatQuickRepliesDifferentFormat = _.get(message, "data.quickReplies");
      const facebookQuickReplies = _.get(
        message,
        "data.content[0].attachment.payload.quickReplies"
      );
      const finalQuickReplies =
        webchatQuickReplies || webchatQuickRepliesDifferentFormat || facebookQuickReplies;
      return finalQuickReplies;
    },
    isImageOrFileOrVideoMessage(message) {
      const isImages = _.get(message, "data.content[0].images");
      const isFiles = _.get(message, "data.content[0].files");
      const isVideos = _.get(message, "data.content[0].videos");
      const finalIsImageOrFileOrVideoMessage = !!(isImages || isFiles || isVideos);

      return finalIsImageOrFileOrVideoMessage;
    },
    isTextMessage(message) {
      const images = _.get(message, "data.content[0].images");
      const files = _.get(message, "data.content[0].files");
      const videos = _.get(message, "data.content[0].videos");
      // validating images, files, and videos item not null
      const isImageMessage =
        _.get(message, "data.content[0].image") ||
        (Array.isArray(images) && _.compact(images).length > 0);
      const isFileMessage = Array.isArray(files) && _.compact(files).length > 0;
      const isVideoMessage = Array.isArray(videos) && _.compact(videos).length > 0;
      const isListStyle = _.get(message, "data.listStyle") === "compact";

      return !(isImageMessage || isFileMessage || isVideoMessage || isListStyle);
    },
    isMultipleTextMessage(message) {
      const isWebchatMultipleMessage = _.get(message, "data.content.length") > 1;
      const isFacebookMultipleMessageDifferntFormat =
        _.get(message, "data.content[0].attachment.payload.length") > 1;
      const isFacebookMultipleMessage =
        _.get(message, "data.content[0].attachment.payload.elements.length") > 1;

      const finalIsMultipleMessage =
        isWebchatMultipleMessage ||
        isFacebookMultipleMessage ||
        isFacebookMultipleMessageDifferntFormat;
      return finalIsMultipleMessage;
    },

    getMultipleTextMessageContent(message) {
      const facebookMultipleMessage = _.get(message, "data.content[0].attachment.payload.elements");

      const webchatMultipleMessage = _.get(message, "data.content");
      const finalMessages = facebookMultipleMessage || webchatMultipleMessage;
      return finalMessages;
    },
  },
};
</script>
