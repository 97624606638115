import _ from "lodash";

import { rest } from "@/store/api";
import { RootState } from "@/store/types";
import { ActionTree } from "vuex/types";
import { CustomerCareState } from "./types";

const actions: ActionTree<CustomerCareState, RootState> = {
  CUSTOMER_CARE_GET_DASHBOARDS: async ({ state, commit, dispatch }) => {
    const result = await rest("get", "get_metabase_dashboards_by_collection");
    commit("CUSTOMER_CARE_SET_DASHBOARDS", _.sortBy(result, ["id"]));
    return result;
  },
  CUSTOMER_CARE_SET_SELECTED_TAB: async ({ state, commit, dispatch }, payload) => {
    commit("CUSTOMER_CARE_SET_SELECTED_TAB", payload);
  },
  CUSTOMER_CARE_SET_EDITOR_VISIBLE: async ({ state, commit, dispatch }, payload) => {
    commit("CUSTOMER_CARE_SET_EDITOR_VISIBLE", payload);
  },
  CUSTOMER_CARE_TOGGLE_EDITOR_VISIBLE: async ({ state, commit, dispatch }) => {
    commit("CUSTOMER_CARE_TOGGLE_EDITOR_VISIBLE");
  },
};

export default actions;
