<template>
  <div>
    <div id="plugins-header">
      <h1 class="main-title">Plugins</h1>
      <div class="helper-text">Authorize plugins and have more powerful bots!</div>
    </div>
    <div id="plugins-body">
      <div class="custom-plugin">
        <div class="plugin-item-wrapper">
          <div class="plugin-item">
            <div class="plugin-header">
              <div class="plugin-info">
                <div class="plugin-img">
                  <img :src="customizeIcon" alt="" />
                </div>
                <div class="plugin-name">
                  <p>Custom Plugin</p>
                  <span>AI Developer</span>
                </div>
              </div>
              <div class="plugin-button">
                <el-button type="primary" size="small" @click="showModal()">Add</el-button>
              </div>
            </div>
            <div class="plugin-body">Add your own ChatGPT compatible plugin.</div>
          </div>
        </div>
      </div>
      <div class="available-plugin">
        <p>Available Plugins</p>
        <div class="plugin-items">
          <div class="plugin-item-wrapper" v-for="(plugin, index) in availablePlugins" :key="index">
            <div class="plugin-item">
              <div class="plugin-header">
                <div class="plugin-info">
                  <div class="plugin-img">
                    <img :src="plugin.icon" alt="" />
                  </div>
                  <div class="plugin-name">
                    <p>{{ plugin.name }}</p>
                    <span>{{ plugin.provider }}</span>
                  </div>
                </div>
                <div class="plugin-button">
                  <el-button
                    v-if="!plugin.enabled"
                    type="primary"
                    size="small"
                    @click="showModal(plugin)"
                    >Enable</el-button
                  >
                  <el-button v-else type="plain" size="small">Disable</el-button>
                </div>
              </div>
              <div class="plugin-body">{{ plugin.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <component
      :is="selectedModal"
      v-if="selectedModal"
      :dialogVisible="dialogVisible"
      @updateDialogVisible="updateDialogVisible"
      :selectedPlugin="selectedPlugin"
    />
  </div>
</template>

<script>
import JiraModal from "./JiraModal.vue";
import GithubModal from "./GithubModal.vue";
import KlarnaModal from "./KlarnaModal.vue";
import CustomizeModal from "./CustomizeModal.vue";
import customizeIcon from "./assets/icons/customize.png";
import microsoftGraphIcon from "./assets/icons/ms-graph.png";
import jiraIcon from "./assets/icons/jira.png";
import githubIcon from "./assets/icons/github.png";
import klarnaIcon from "./assets/icons/klarna.png";

export default {
  data() {
    return {
      customizeIcon,
      availablePlugins: [
        {
          name: "Microsoft Graph",
          provider: "Microsoft",
          description:
            "Use your Microsoft Account to access your personal Graph information and Microsoft services.",
          icon: microsoftGraphIcon,
          modal: "MicrosoftGraph",
          enabled: true,
        },
        {
          name: "Jira",
          provider: "Altassian",
          description:
            "Authorize KeyReply to link with Jira and retrieve specific issues by providing the issue key.",
          icon: jiraIcon,
          modal: "Jira",
          enabled: false,
        },
        {
          name: "Github",
          provider: "Microsoft",
          description:
            "Integrate Github with KeyReply, i.e., allow KeyReplyBot to list active Pull Requests for you.",
          icon: githubIcon,
          modal: "Github",
          enabled: false,
        },
        {
          name: "Klarna Shopping",
          provider: "Klarna",
          description: "Search and compare prices from thousands of online shops.",
          icon: klarnaIcon,
          modal: "Klarna",
          enabled: false,
        },
      ],
      dialogVisible: false,
      selectedModal: null,
      selectedPlugin: null,
    };
  },
  methods: {
    showModal(plugin) {
      this.selectedPlugin = plugin;
      switch (plugin?.modal) {
        case "Jira":
          this.selectedModal = JiraModal;
          break;
        case "Github":
          this.selectedModal = GithubModal;
          break;
        case "Klarna":
          this.selectedModal = KlarnaModal;
          break;
        default:
          this.selectedModal = CustomizeModal;
      }
      this.dialogVisible = true;
    },
    updateDialogVisible(value) {
      this.dialogVisible = value;
      if (!value) {
        this.selectedModal = null;
        this.selectedPlugin = null;
      }
    },
  },
};
</script>

<style lang="scss">
#plugins-header {
  margin-top: 20px;
  .helper-text {
    font-size: 14px;
    color: #909399;
    margin-top: 5px;
  }
}

#plugins-body {
  margin-top: 30px;
  .plugin-item-wrapper {
    width: 50%;
    padding: 5px;
    box-sizing: border-box;
    .plugin-item {
      width: 100%;
      border: 1px solid #ebeef5;
      padding: 15px;
      border-radius: 5px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      box-sizing: border-box;

      .plugin-header {
        display: flex;
        justify-content: space-between;
        .plugin-info {
          display: flex;
          .plugin-img {
            width: 50px;
            height: 50px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .plugin-name {
            margin-left: 20px;
            p {
              margin: 0;
              font-weight: 500;
              font-size: 18px;
            }
            span {
              font-size: 14px;
            }
          }
        }
      }
      .plugin-body {
        margin-top: 30px;
      }
    }
  }

  .available-plugin {
    margin-top: 20px;
    p {
      margin-bottom: 10px;
      margin-left: 10px;
      font-size: 24px;
      font-weight: 500;
    }
    .plugin-items {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>
