<template>
  <div class="changes-container" style="background-color: #fff; height: fit-content">
    <el-tabs v-model="activeName">
      <el-tab-pane label="Changes" name="default"></el-tab-pane>
      <el-tab-pane label="Advanced" name="json"></el-tab-pane>
    </el-tabs>
    <div v-if="havingChanges" class="changes-wrapper">
      <div v-if="activeName === 'default'">
        <p
          v-for="(change, index) in getChanges()"
          :key="index"
          :style="{
            color: change.color,
          }"
        >
          {{ change.path + " " + change.value }}
        </p>
      </div>
      <div v-else-if="activeName === 'json'" v-html="getChangesAsJson()"></div>
    </div>
    <div
      v-else
      class="changes-wrapper"
      style="place-content: center; place-items: center; display: grid; min-height: 500px"
    >
      There is no change has been made to this form yet.
    </div>
    <div class="actions" style="height: 10%">
      <el-button @click="onSave" type="success" :disabled="!havingChanges" style="z-index: 999">
        <el-icon name="check"></el-icon>
        Save
      </el-button>
      <el-button @click="onRevert" type="warning" :disabled="!havingChanges" style="z-index: 999">
        <el-icon name="close"></el-icon>
        Revert
      </el-button>
      <el-button
        @click="onDelete"
        type="danger"
        v-if="!form.settings?.nonDelete && shouldShowDeleteAction"
        style="z-index: 999"
      >
        <el-icon name="remove"></el-icon>
        Delete
      </el-button>

      <div>
        <el-divider />
        <el-button @click="onGenerate" type="primary">
          <el-icon name="magic-stick"></el-icon>
          Generate content node
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as HumanDiff from "human-object-diff";
import * as jsondiffpatch from "jsondiffpatch/dist/jsondiffpatch.umd";

import _ from "lodash";

const HDiff = new HumanDiff({
  objectName: "$",
  templates: {
    N: `DOTPATH|added NEWVALUE`,
    D: `DOTPATH|removed OLDVALUE`,
    E: `DOTPATH|changed OLDVALUE to NEWVALUE`,
    I: `DOTPATH|added NEWVALUE`,
    R: `DOTPATH|removed OLDVALUE`,
    NS: `DOTPATH|added`,
    DS: `DOTPATH|removed`,
    ES: `DOTPATH|changed`,
    IS: `DOTPATH|added a value`,
    RS: `DOTPATH|removed a value`,
    AES: `DOTPATH|changed a value`,
  },
});

export default {
  name: "FormDetailActions",
  data: () => ({
    activeName: "default",
  }),
  props: {
    form: Object,
    originalForm: Object,
    shouldShowDeleteAction: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.getChanges();
  },
  methods: {
    getChanges() {
      let differences = HDiff.diff(this.originalForm, this.form);
      differences = _.map(differences, (change) => {
        const index = change.indexOf("|");
        const path = change.substring(0, index);
        const value = change.substring(index + 1);
        let type = "changed";

        if (change.includes("added")) {
          type = "added";
        } else if (change.includes("removed")) {
          type = "removed";
        } else if (change.includes("changed")) {
          type = "changed";
        }

        const color = {
          added: "green",
          removed: "red",
          changed: "orange",
        }[type];

        return {
          path,
          value,
          color,
        };
      });
      return differences;
    },
    getChangesAsJson() {
      jsondiffpatch.formatters.html.hideUnchanged();
      const delta = jsondiffpatch.diff(this.originalForm, this.form);
      const changes = jsondiffpatch.formatters.html.format(delta, this.originalForm);
      return changes;
    },
    onSave() {
      this.$emit("form-action", {
        action: "save",
        callback: () => {
          this.originalForm.name = this.form.name;
          this.originalForm.description = this.form.description;
          this.originalForm.settings = _.cloneDeep(this.form.settings);
        },
      });
    },
    onGenerate() {
      this.$confirm(
        `Are you sure? System will create content nodes and triggers with prefix "form-${this.form.id}". This will overwrite any changes you made to the relevants nodes. You will be redirected to the relevant content nodes afterwards. You can test by typing 'form-${this.form.id}_welcome'`
      )
        .then((_) => {
          this.$emit("form-action", {
            action: "generate",
          });
        })
        .catch((_) => {});
    },
    onDelete() {
      this.$confirm("Do you want to delete to form and its related submit results?")
        .then(() => {
          this.$emit("form-action", { action: "delete" });
        })
        .catch(() => {});
    },
    onRevert() {
      this.$confirm("Do you want to revert to original form?")
        .then(() => {
          this.form.name = this.originalForm.name;
          this.form.description = this.originalForm.description;
          this.form.settings = { ...this.originalForm.settings };
          this.$emit("form-action", { action: "reverted" });
        })
        .catch(() => {});
    },
  },
  computed: {
    havingChanges() {
      return !_.isEqual(this.originalForm, this.form);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~jsondiffpatch/dist/formatters-styles/html.css";

.changes-container {
  overflow: auto;
  display: grid;
  grid-template-columns: auto;

  .changes-wrapper {
    margin: 20px;
    padding: 10px;
    border: 2px solid rgba(0, 0, 0, 0.05);
    background: #fdfdfd;
    border-radius: 4px;
    overflow: auto;
  }

  .actions {
    float: right;
    margin: 0 20px 20px;
    text-align: right;
  }
}
</style>

<style>
.changes-container .el-tabs__content {
  position: static !important;
}
</style>
