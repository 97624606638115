<template>
  <div class="chat-widget">
    <div class="chat-header">
      <h3>Preview</h3>
    </div>
    <div class="chat-body">
      <div v-for="(message, index) in messages" :key="index" style="display: flex">
        <div
          :class="{ 'user-message': !message.bot, 'bot-message': message.bot }"
          class="chat-message"
          v-if="message.text.length > 0"
        >
          <p class="chat-bubble" v-html="renderedText(message.text)"></p>
        </div>
      </div>
      <!-- typing indicator-->
      <div class="chat-typing" v-if="isTyping">
        <p>Bot is typing...</p>
      </div>
    </div>
    <div class="chat-footer">
      <input
        :disabled="isTyping"
        v-model="newMessage"
        @keyup.enter="sendMessage"
        placeholder="Type a message..."
      />
      <el-button v-if="!isTyping" @click="sendMessage">Send</el-button>
    </div>
  </div>
</template>

<script>
import { mdToHtml } from "@/helpers/markdown";
import { v4 as uuid } from "uuid";
import signalRHubConnection from "./signalRHubConnection";

export default {
  props: {
    selectedPersona: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      messages: [],
      newMessage: "",
      state: {
        genai_session_id: uuid(),
        genai_selected_persona: "",
        signalRConnectionId: "",
        session_history: "",
      },
      tempMessage: {
        text: "",
        bot: true,
      },
      isTyping: false,
      botIsGenerateAnswer: false,
    };
  },
  methods: {
    async sendMessage() {
      try {
        if (this.newMessage.trim() !== "") {
          this.isTyping = true;
          this.messages.push({ text: this.newMessage, bot: false });
          this.$nextTick(() => {
            this.scrollToBottom();
          });

          const tempMessage = this.newMessage;
          this.newMessage = "";

          const botResponse = await this.$store.dispatch("GENAI_GET_RESPONSE", {
            text: tempMessage,
            state: {
              ...this.state,
              genai_selected_persona: this.selectedPersona.name,
              session_history: JSON.stringify(this.messages),
            },
          });

          if (!this.selectedPersona.streaming_response) {
            this.messages.push({ text: botResponse.text, bot: true });
            this.$nextTick(() => {
              this.scrollToBottom();
              this.isTyping = false;
            });
          }
        }
      } catch (error) {
        this.isTyping = false;
      }
    },
    renderedText(text) {
      return mdToHtml(text);
    },
    scrollToBottom() {
      const chatBody = this.$el.querySelector(".chat-body");
      chatBody.scrollTop = chatBody.scrollHeight;
    },
  },
  computed: {
    settings() {
      return this.$store.state.modules.genai;
    },
  },
  async created() {
    const hubConnection = signalRHubConnection(this.settings.SEMANTIC_SERVICE_BASE_URL);

    hubConnection.on("GetConnectionId", (connectionId) => {
      this.state.signalRConnectionId = connectionId;
    });

    hubConnection.on("StartReceiveMessage", () => {
      this.tempMessage = {
        text: "",
        bot: true,
      };

      if (this.selectedPersona.streaming_response) {
        this.messages.push(this.tempMessage);
      }
      this.isTyping = true;
    });

    hubConnection.on("ReceiveMessage", (message) => {
      if (message) {
        const currentMessage = _.last(this.messages);
        const currentMessageContent = currentMessage.text;
        const currentMessageIndex = this.messages.length - 1;

        const newMessage = currentMessageContent.concat(message);
        currentMessage.text = newMessage;

        this.messages[currentMessageIndex] = currentMessage;
        this.$nextTick(this.scrollToBottom);
      }
    });

    hubConnection.on("EndReceiveMessage", () => {
      this.isTyping = false;
    });

    await hubConnection.start();
    await hubConnection.invoke("GetConnectionId");
  },
};
</script>

<style scoped>
.chat-widget {
  border: 1px solid #dedede;
  width: 30%;
  height: 600px;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  min-height: 575px;
}

.chat-header {
  background-color: #f5f5f5;
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ccc;
  background-color: #1d57d8;
  color: #fff;
}

.chat-body {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: #f5f8ff;
  display: flex;
  flex-direction: column;
}

.chat-message {
  display: inline-block;
  margin-bottom: 10px;
  width: fit-content;
  padding: 10px;
  border-radius: 10px;
  background-color: #f9f9f9;
  max-width: 80%;
  word-wrap: break-word;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.user-message {
  margin-left: auto;
  color: #000;
}

.bot-message {
  text-align: left;
  color: #fff;
  background-color: #007bff;
}

.chat-footer {
  display: flex;
  border-top: 1px solid #ccc;
  position: relative;
}

.chat-footer input {
  flex: 1;
  padding: 20px;
  border: none;
  outline: none;
}

.chat-footer input:focus {
  outline: none;
}

.chat-footer button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: #1d57d8;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
}

.chat-typing {
  font-size: 14px;
  margin-top: auto;
}

.chat-typing p {
  margin: 0;
}
</style>
