<template>
  <div>
    <div style="margin-bottom: 10px">User Message</div>
    <div>
      <el-input
        v-model="value.data.userMessage"
        placeholder="Enter user message"
        style="width: 100%"
      ></el-input>
    </div>
    <div style="margin-top: 20px">
      <EventNodeSelectorNext v-model="value" />
    </div>
  </div>
</template>

<script>
import EventNodeSelectorNext from "../Next.vue";

export default {
  name: "GenAIResponse",
  props: ["value"],
  components: {
    EventNodeSelectorNext,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
