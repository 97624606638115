import { graph } from "@/store/api";
import { RootState } from "@/store/types";
import gql from "graphql-tag";
import { ActionTree } from "vuex";
import { OutreachState, OUT_REACH_TAG_TYPE } from "./types";

const actions: ActionTree<OutreachState, RootState> = {
  FETCH_OUTREACH_CONTACTS: async (
    { commit },
    { take = 10, page = 1, startDate = "", endDate = "", filter, sort }
  ) => {
    commit("SET_OUTREACH_LOADING", true);
    return graph
      .query({
        query: gql`
          query (
            $take: Int!
            $page: Int!
            $startDate: String
            $endDate: String
            $filter: [JSON]
            $sort: JSON
          ) {
            outReachContactAPI {
              getAllOutReachContacts(
                take: $take
                page: $page
                startDate: $startDate
                endDate: $endDate
                filter: $filter
                sort: $sort
              )
            }
          }
        `,
        variables: {
          take,
          page,
          startDate,
          endDate,
          filter,
          sort,
        },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        const result = data.outReachContactAPI.getAllOutReachContacts;
        commit("SET_OUTREACH_CONTACTS", result);
        commit("SET_OUTREACH_LOADING", false);
      });
  },
  FETCH_COUNT_OUTREACH_CONTACT: async ({ commit }, { take = 10, page = 1, filter }) => {
    const { data } = await graph.query({
      query: gql`
        query ($take: Int!, $page: Int!, $filter: [JSON]) {
          outReachContactAPI {
            getAllOutReachContacts(take: $take, page: $page, filter: $filter)
          }
        }
      `,
      variables: {
        take,
        page,
        filter,
      },
      fetchPolicy: "network-only",
    });
    return data.outReachContactAPI.getAllOutReachContacts.count;
  },
  FETCH_OUTREACH_SEGMENTS: async ({ commit }) => {
    return graph
      .query({
        query: gql`
          query {
            outReachTagStageAPI {
              getOutReachTags
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((res) => {
        const result = res.data.outReachTagStageAPI.getOutReachTags;
        const segmentsTags = result.filter((tag) => tag.type === OUT_REACH_TAG_TYPE.TAG);
        const segmentsStages = result.filter((tag) => tag.type === OUT_REACH_TAG_TYPE.STAGE);
        commit("SET_OUTREACH_TAGS", segmentsTags);
        commit("SET_OUTREACH_STAGES", segmentsStages);
        commit("SET_OUTREACH_LOADING", false);
      });
  },
  FETCH_CONTACT_ATTRIBUTES: async ({ commit }) => {
    return graph
      .query({
        query: gql`
          query {
            outReachContactAPI {
              getAllContactAttributes
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((res) => {
        const result = res.data.outReachContactAPI.getAllContactAttributes;
        commit("SET_CONTACT_ATTRIBUTES", result);
        commit("SET_OUTREACH_LOADING", false);
      });
  },
  FETCH_OUTREACH_METRICS: async ({ commit }) => {
    const result = await graph.query({
      query: gql`
        query ($filter: [JSON]) {
          outReachContactAPI {
            countAllOutReachContacts(filter: $filter)
          }
          outReachGroupFilterAPI {
            getAllOutReachGroupFilters
          }
        }
      `,
      variables: {
        filters: [],
      },
      fetchPolicy: "network-only",
    });
    const counts = result.data.outReachContactAPI.countAllOutReachContacts.filterResult;
    const metrics = result.data.outReachGroupFilterAPI.getAllOutReachGroupFilters.map((item, i) => ({
      id: item.id,
      name: item.groupName,
      color: item.colorValue,
      filterList: JSON.parse(item.filters),
      value: counts[i].filter,
    }));
    commit("SET_OUTREACH_METRICS", metrics);
  },
  ADD_OUTREACH_METRIC: async ({ commit, state }, newMetric) => {
    const groupFilters = [...Object.values(state.metrics), newMetric].map(item =>  ({
      groupName: item.name,
      colorValue: item.color,
      filters: JSON.stringify(Object.values(item.filterList)),
    }));
    await graph.mutate({
      mutation: gql`
        mutation ($groupFilters: [JSON]) {
          outReachGroupFilterAPI {
            outReachCreateGroupFilter(groupFilters: $groupFilters)
          }
        }
      `,
      variables: {
        groupFilters,
      }
    });
    const result = await graph.query({
      query: gql`
        query ($filter: [JSON]) {
          outReachContactAPI {
            countAllOutReachContacts(filter: $filter)
          }
          outReachGroupFilterAPI {
            getAllOutReachGroupFilters
          }
        }
      `,
      variables: {
        filters: [],
      },
      fetchPolicy: 'no-cache',
    });
    const counts = result.data.outReachContactAPI.countAllOutReachContacts.filterResult;
    const metrics = result.data.outReachGroupFilterAPI.getAllOutReachGroupFilters.map((item, i) => ({
      id: item.id,
      name: item.groupName,
      color: item.colorValue,
      filterList: JSON.parse(item.filters),
      value: counts[i].filter,
    }));
    commit("SET_OUTREACH_METRICS", metrics);
  },
  EDIT_OUTREACH_METRIC: async ({ commit, state }, editedMetric) => {
    const updatedMetrics = { ...state.metrics, [editedMetric.id]: editedMetric };
    const groupFilters = Object.values(updatedMetrics).map((item: any) =>({
      id: item.id,
      groupName: item.name,
      colorValue: item.color,
      filters: JSON.stringify(Object.values(item.filterList)),
    }));
    await graph.mutate({
      mutation: gql`
        mutation ($groupFilters: [JSON]) {
          outReachGroupFilterAPI {
            outReachCreateGroupFilter(groupFilters: $groupFilters)
          }
        }
      `,
      variables: {
        groupFilters,
      }
    });
    const result = await graph.query({
      query: gql`
        query ($filter: [JSON]) {
          outReachContactAPI {
            countAllOutReachContacts(filter: $filter)
          }
          outReachGroupFilterAPI {
            getAllOutReachGroupFilters
          }
        }
      `,
      variables: {
        filters: [],
      },
      fetchPolicy: 'no-cache',
    });
    const counts = result.data.outReachContactAPI.countAllOutReachContacts.filterResult;
    const metrics = result.data.outReachGroupFilterAPI.getAllOutReachGroupFilters.map((item, i) => ({
      id: item.id,
      name: item.groupName,
      color: item.colorValue,
      filterList: JSON.parse(item.filters),
      value: counts[i].filter,
    }));
    commit("SET_OUTREACH_METRICS", metrics);
  },
  DELETE_OUTREACH_METRIC: async ({ commit, state }, metricId) => {
    const groupFilters = Object.values(state.metrics).filter(metric => metric.id !== metricId).map((item: any) =>({
      groupName: item.name,
      colorValue: item.color,
      filters: JSON.stringify(Object.values(item.filterList)),
    }));
    await graph.mutate({
      mutation: gql`
        mutation ($groupFilters: [JSON]) {
          outReachGroupFilterAPI {
            outReachCreateGroupFilter(groupFilters: $groupFilters)
          }
        }
      `,
      variables: {
        groupFilters,
      }
    });
    const result = await graph.query({
      query: gql`
        query ($filter: [JSON]) {
          outReachContactAPI {
            countAllOutReachContacts(filter: $filter)
          }
          outReachGroupFilterAPI {
            getAllOutReachGroupFilters
          }
        }
      `,
      variables: {
        filters: [],
      },
      fetchPolicy: 'no-cache',
    });
    const counts = result.data.outReachContactAPI.countAllOutReachContacts.filterResult;
    const metrics = result.data.outReachGroupFilterAPI.getAllOutReachGroupFilters.map((item, i) => ({
      id: item.id,
      name: item.groupName,
      color: item.colorValue,
      filterList: JSON.parse(item.filters),
      value: counts[i].filter,
    }));
    commit("SET_OUTREACH_METRICS", metrics);
  }
};

export default actions;
