<template>
  <div>
    <el-dialog
      title="Tips"
      :visible.sync="shouldShowTagEditor"
      width="40%"
      :before-close="handleClose"
    >
      <div>
        <el-table :data="document_tags" border style="width: 100%">
          <el-table-column label="Enable" align="center" width="100px">
            <template slot-scope="scope">
              <el-checkbox
                :checked="isSelectedTagContains(scope.row.name)"
                @change="handleTagChange(scope.row.name, $event)"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="Category"> </el-table-column>
          <el-table-column label="Tags">
            <template slot-scope="scope">
              <div style="display: inline-block; width: 100%">
                <el-select
                  placeholder="Select tags..."
                  multiple=""
                  v-model="selectedTags[scope.row.name]"
                  style="width: 100%"
                  :disabled="!isSelectedTagContains(scope.row.name)"
                >
                  <el-option
                    v-for="(tag, index) in scope.row.tags"
                    :key="index"
                    :label="tag"
                    :value="tag"
                  ></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-top: 30px; font-size: 16px">
        <span>Note: You must re-upload this website to apply the edit.</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="handleConfirm" :loading="isLoading">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { v4 as uuid } from "uuid";

export default {
  props: ["shouldShowTagEditor", "selectedWebsite", "isLoading"],
  data() {
    return {
      selectedTags: {},
      newTags: [],
      tempSelectedTags: {},
      tempNewArticle: {},
    };
  },
  methods: {
    handleClose() {
      this.$confirm("Are you sure you want to close this window", "warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "info",
      }).then(() => {
        this.$emit("handleCloseTagEditor");
      });
    },
    isSelectedTagContains(tagName) {
      return _.has(this.tempSelectedTags, tagName);
    },
    handleTagChange(itemName, value) {
      if (value) {
        this.$set(this.selectedTags, itemName, []);
        this.$set(this.tempSelectedTags, itemName, []);
      } else {
        this.$delete(this.selectedTags, itemName);
        this.$delete(this.tempSelectedTags, itemName, []);
      }
    },
    handleConfirm() {
      const newWebsite = _.cloneDeep(this.selectedWebsite);
      const tags = {};
      Object.keys(this.selectedTags).forEach((key) => {
        if (this.selectedTags[key].length > 0) {
          tags[key] = this.selectedTags[key];
        }
      });

      newWebsite.id = uuid();
      newWebsite.isUpload = false;
      newWebsite.isScraping = false;
      newWebsite.tags = tags;

      this.$emit("handleUpdateWebsiteTag", { newWebsite, oldWebsite: this.selectedWebsite });
    },
  },
  computed: {
    modules: {
      get() {
        return this.$store.state.modules;
      },
      set(value) {
        this.$store.state.modules = value;
      },
    },
    genai_settings() {
      return this.modules.genai;
    },
    document_tags() {
      return this.genai_settings?.knowledge_document.tags || [];
    },
  },
  watch: {
    "selectedWebsite.tags": {
      handler(newTags) {
        if (newTags) {
          this.selectedTags = _.cloneDeep(newTags);
          this.tempSelectedTags = _.cloneDeep(newTags);
        }
      },
      immediate: true, // This ensures the watcher runs on initial data load
    },
  },
  mounted() {
    if (this.selectedWebsite.tags) {
      this.selectedTags = _.cloneDeep(this.selectedArticle.tags);
      this.tempSelectedTags = _.cloneDeep(this.selectedArticle.tags);
    }
  },
};
</script>

<style lang="scss" scoped></style>
