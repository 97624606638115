<template>
  <el-card>
    <el-row type="flex" align="middle">
      <el-col>
        <small style="font-weight: bold">Filter by date range</small>
        <el-date-picker
          v-model="searchDateRange"
          type="daterange"
          size="mini"
          format="dd-MM-yyyy"
          style="width: 400px; margin-left: 15px; margin-bottom: 0"
          align="right"
          @change="handleDateRangeChange"
          unlink-panels
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-col>
      <el-col style="float: right">
        <div style="float: right; display: flex; flex-direction: row">
          <el-dropdown @command="exportCSV" trigger="click">
            <el-button
              style="margin-right: 10px"
              type="primary"
              :loading="downloading"
              size="mini"
              :disabled="pagedUsers.length === 0"
              icon="el-icon-download"
              >Export<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="visitor">
                <i class="el-icon-user"></i>Visitor Data
              </el-dropdown-item>
              <el-dropdown-item command="interaction">
                <i class="el-icon-chat-line-square"></i>Interaction Data
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-input
            ref="search_state"
            style="min-width: 120px; width: 30vw"
            v-model="search"
            size="mini"
            placeholder="Search"
            clearable
            suffix-icon="el-icon-search"
          />
        </div>
      </el-col>
    </el-row>
    <br />
    <div class="block">
      <el-pagination
        background
        small
        layout="prev, pager, next"
        :pageSize="pageSize"
        :current-page.sync="currentPage"
        :total="noOfItems"
        @prev-click="togglePage"
        @next-click="togglePage"
        @current-change="togglePage"
        @size-change="handleSizeChange"
        style="display: inline-block"
      ></el-pagination>
      <div style="display: inline-block">
        <span>{{ noOfItems }} {{ noOfItems === 1 ? "record" : "records" }}</span>
      </div>
    </div>
    <div>
      <el-table
        v-loading="loading"
        @selection-change="handleSelectionChange"
        element-loading-text="Loading user list.."
        :data="pagedUsers"
        style="width: 100%"
        size="mini"
      >
        <el-table-column type="selection" width="50"> </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="props">
            <Visitor :user="props.row" :viewMeta="actualMetaData"></Visitor>
          </template>
        </el-table-column>
        <el-table-column label="User ID" width="100" prop="RowKey" sortable>
          <template slot-scope="scope">
            <p style="line-height: 1; margin-bottom: 0; margin-top: 0">
              <small style="font-weight: bold">{{ scope.row.RowKey | trim(11) }}</small>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Last Seen" prop="Timestamp" sortable>
          <template slot-scope="scope">
            <small style="line-height: 1">
              {{ scope.row.Timestamp | moment }}
            </small>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(meta, name, index) in viewMetaData.fields"
          :label="meta.displayValue"
          :key="index"
          :column-key="name"
          :min-width="meta.columnWidth ? meta.columnWidth : '100px'"
          :prop="name"
          sortable
          :filters="
            meta.listValues ? meta.listValues.map((v) => ({ text: v, value: v })) : undefined
          "
          filter-placement="right"
          :filter-change="tableFilter"
          header-align="left"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="meta.displayType === 'tag'"
              size="small"
              :type="meta.tagType[scope.row[name]]"
            >
              {{ scope.row[name] }}
            </el-tag>
            <span v-else :style="{ color: scope.row[name] === 'N/A' ? '#C0C4CC' : 'black' }">
              {{ meta.dataType === "datetime" ? formatDate(scope.row[name]) : scope.row[name] }}
            </span>
          </template>
        </el-table-column>
        <el-table-column fixed="right">
          <template slot-scope="scope">
            <el-dropdown>
              <span class="el-dropdown-link">... </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item style="padding: 0; line-height: 0"
                  ><VisitorInteractions :visitor="scope.row" :search-date-range="searchDateRange"
                /></el-dropdown-item>
                <el-dropdown-item style="padding: 0; line-height: 0"
                  ><TestPanel v-if="isFaqEnabled" :visitor="scope.row"
                /></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-card>
</template>
<script>
import { exportUsersToXLS, exportInteractionsToXLS } from "@/helperMethods/visitors";
import moment from "moment";
import _ from "lodash";
import { defaultPickerOptions, updateUrlQuery } from "@/helperMethods/util";
import Visitor from "./VisitorRow";
import XLSX from "xlsx";
import VisitorInteractions from "./VisitorInteractions";
import TestPanel from "./TestPanel";
import { isDateRangeValid } from "@/components/LiveChat/helpers/filters";

export default {
  components: {
    Visitor,
    VisitorInteractions,
    TestPanel,
  },
  data() {
    const startDate = _.get(this.$route, "query.startDate");
    const endDate = _.get(this.$route, "query.endDate");
    return {
      search: _.get(this.$route, "query.search") || "",
      loading: false,
      downloading: false,
      viewMetaData: {},
      actualMetaData: {},
      pagedUsers: [],
      totalRecordCount: 0,
      searchDateRange:
        startDate && endDate
          ? [moment(startDate).toDate(), moment(endDate).toDate()]
          : [moment().toDate(), moment().toDate()],
      pageSize: 25,
      currentPage: 1,
      pickerOptions: {
        shortcuts: _.take(defaultPickerOptions?.shortcuts, 3),
      },
      selectedRows: [],
    };
  },
  computed: {
    noOfItems() {
      return this.totalRecordCount;
    },
    isFaqEnabled() {
      return _.get(this, "$store.state.modules.faq.enabled", false);
    },
  },
  watch: {
    // whenever question changes, this function will run
    search: _.debounce(function (newSearch, oldSearch) {
      this.currentPage = 1;
      this.getUsers();
    }, 1000),
  },
  filters: {
    moment: function (date) {
      return moment(date).local().format("D MMM YY h:mm A");
    },
  },
  methods: {
    isDateRangeValid,
    tableFilter(change) {
      console.log(change);
    },
    formatDate(date) {
      return date ? moment(date).local().fromNow() : "N/A";
    },
    togglePage(pageNo) {
      this.currentPage = pageNo;
      this.getUsers();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getUsers();
    },
    handleDateRangeChange() {
      this.currentPage = 1;
      this.getUsers();
    },
    loadViewMetaData() {
      this.actualMetaData = this.$store.state.modules["visitors"].config;
      //prepare view metadata. filter out fields not to be shown in the grid
      const viewMetaData = {
        fields: {},
      };
      _.forEach(this.actualMetaData.fields, (field, key) => {
        if (field.hideFromTable !== true) {
          viewMetaData.fields[key] = field;
        }
      });
      this.viewMetaData = viewMetaData;
    },
    async getUsers() {
      try {
        let startDate = "";
        let endDate = "";
        if (this.searchDateRange && this.searchDateRange.length > 0) {
          startDate = this.searchDateRange[0];
          endDate = this.searchDateRange[1];
        }

        if (!this.isDateRangeValid({ startDate, endDate })) {
          // revert date range
          const searchStr = location.hash.substring(location.hash.indexOf("?"));
          const params = new URLSearchParams(searchStr);
          const queryStartDate = params.get("startDate") || moment();
          const queryEndDate = params.get("endDate") || moment();
          this.searchDateRange = [moment(queryStartDate).toDate(), moment(queryEndDate).toDate()];

          this.$notify.warning({
            title: "Info",
            position: "bottom-right",
            message: `Date range should not be greater than 7 days`,
          });

          return;
        }

        this.loading = true;

        updateUrlQuery({
          search: this.search,
          startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
        });

        const _response = await this.$store.dispatch("FETCH_USERS_BY_PAGE", {
          page: this.currentPage,
          pageSize: this.pageSize,
          searchString: this.search,
          startDate,
          endDate,
        });
        this.pagedUsers = _.get(_response, "data.fetchUsersByPage.rows", []);
        this.totalRecordCount = _.get(_response, "data.fetchUsersByPage.count", 0);
      } catch (err) {
        console.error(err);
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: `Error fetching users.`,
        });
      } finally {
        this.loading = false;
        setTimeout(() => {
          if (this.search) {
            this.$refs["search_state"].$el.querySelector("input").focus();
          }
        }, 1000);
      }
    },
    exportCSV(command) {
      let startDate = "";
      let endDate = "";
      if (this.searchDateRange && this.searchDateRange.length > 0) {
        startDate = this.searchDateRange[0];
        endDate = this.searchDateRange[1];
      }
      if (command === "visitor") {
        this.downloading = true;
        this.$store
          .dispatch("FETCH_USERS_BY_PAGE", {
            page: this.currentPage,
            pageSize: this.pageSize,
            searchString: this.search,
            startDate: startDate,
            endDate: endDate,
            mode: "download",
          })
          .then((response) => {
            const fullUserList = _.get(response, "data.fetchUsersByPage.rows", []);
            const wb = exportUsersToXLS(this.viewMetaData.fields, fullUserList);
            const filename = this.$exportFilename("chatbot_visitors", "xlsx");
            XLSX.writeFile(wb, filename, {});
          })
          .catch((err) => {
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: `Error fetching users.`,
            });
          })
          .finally(() => {
            this.downloading = false;
          });
      }
      if (command === "interaction") {
        if (!this.selectedRows.length) {
          this.$notify.warning({
            title: "Warning",
            position: "bottom-right",
            message: `Please select at least 1 of row(s) to download interaction.`,
          });
          return;
        }
        const selectedUsers = this.selectedRows.map((v) => `${v.RowKey}||${v.PartitionKey}`);
        const rowData = _.groupBy(this.selectedRows, "RowKey");

        this.downloading = true;
        this.$store
          .dispatch("FETCH_USER_INTERACTIONS_BATCH", {
            userIds: selectedUsers,
            startDate: startDate,
            endDate: endDate,
          })
          .then((response) => {
            const fields = this.viewMetaData.fields;
            const rows = _.get(response, "data.getInteractionsBatch.rows", []);
            if (!rows || rows.length === 0) {
              this.$notify.warning({
                title: "Warning",
                position: "bottom-right",
                message: `Selected row(s) have no interaction to download.`,
              });
              return;
            }
            const result = _.chain(response)
              .get("data.getInteractionsBatch.rows", [])
              .groupBy("user_id")
              .value();
            const wb = exportInteractionsToXLS(fields, result, rowData);
            const filename = this.$exportFilename("chatbot_visitors_interactions", "xlsx");
            XLSX.writeFile(wb, filename, {});
          })
          .catch((err) => {
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: `Error fetching users.`,
            });
          })
          .finally(() => {
            this.downloading = false;
          });
      }
    },
    handleSelectionChange(val) {
      this.selectedRows = val;
    },
    progressBarFormat(percentage) {
      if (percentage === 10) return `search in progress... ${percentage}%`;

      if (percentage > 10 && this.totalRecordCount > 0)
        return `found some results, searching for others... ${percentage}%`;
      return `search in progress... ${percentage}%`;
    },
  },
  mounted() {
    const pageSize = _.get(this.$store, "state.modules['visitors'].page_size", 25);
    this.pageSize = pageSize;
    this.loadViewMetaData();
    this.getUsers();
  },
};
</script>

<style scoped>
.el-progress--line {
  margin-top: 20px;
  margin-bottom: 40px;
}
</style>
