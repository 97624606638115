// polyfill all `core-js` features, including early-stage proposals:
import "core-js";
import { Vue, worker } from "./bootstrap";
import Root from "@/Root.vue";
import router from "@/router";
import store from "@/store";
// import Cohere from "cohere-js";

// Cohere.init("JmCLincPzozbhQMoQIT3NA00");
// Cohere.widget("show");

Vue.directive("focus", {
  inserted: function (el) {
    el.querySelector("input")?.focus(); // Focus on mounted el-input
  },
});

Vue.use({
  install(V) {
    const v = V;
    const ua = navigator.userAgent;
    const params = new URLSearchParams(location.search);
    const isMobileQuery = params.get("mode") === "mobile";
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const iOSSDK = !!ua.match(/KeyReplyiOSSDK/i);
    const Android = !!ua.match(/Android/i);
    const Mobile = !!ua.match(/Mobi/i);
    const Mac = !!ua.match(/Macintosh/i);
    const isIEOrEdge = () => {
      if (!navigator) return false;
      let _ieOrEdge = false;
      if (/MSIE 10/i.test(navigator.userAgent)) {
        // This is internet explorer 10
        _ieOrEdge = true;
      }
      if (/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
        // This is internet explorer 9 or 11
        _ieOrEdge = true;
      }
      if (/Edge\/\d./i.test(navigator.userAgent)) {
        // This is Microsoft Edge
        _ieOrEdge = true;
      }
      return _ieOrEdge;
    };

    v.prototype.$device = {
      iOS,
      iOSSDK,
      Android,
      Mobile,
      Mac,
      isMobileQuery,
      ie: isIEOrEdge(),
    };
  },
});

function main() {
  const vm = new Vue({
    router,
    store,
    render: (h) => h(Root as any),
  }).$mount("#app");

  const $keyreply = (window as any).$keyreply;
  if ($keyreply) {
    $keyreply.getVM = () => vm;
  }
  return vm;
}

// Handle incoming messages as commits!
worker.onmessage = ({ data }) => {
  const { type, payload } = data;
  if (type) {
    store.commit(type, payload);
  }
};

main();
