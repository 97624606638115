import actions from "./actions";
import mutations from "./mutations";
import state from "./state";
import { Module } from "vuex/types";
import { GenAIState } from "./types";
import { RootState } from "@/store/types";

const genai: Module<GenAIState, RootState> = {
  // namespaced: true,
  actions,
  mutations,
  state,
};

export default genai;
