<template>
  <div class="detail--container">
    <div class="detail--left" v-if="form.id">
      <el-menu
        ref="formMenu"
        v-model="activeDetailTab"
        default-active="form-detail-basic"
        class="el-menu-vertical-demo"
        @select="onMenuSelect"
      >
        <el-menu-item index="form-detail-basic">
          <i class="el-icon-menu"></i>
          <span>Basic</span>
        </el-menu-item>
        <!-- E-CARE ONLY -->
        <el-menu-item index="form-detail-natural-questions" v-if="isEcareCatIrtForm">
          <i class="el-icon-set-up"></i>
          <span>Questions</span>
        </el-menu-item>
        <el-menu-item index="form-detail-scoring-cat-irt" v-if="isEcareCatIrtForm">
          <i class="el-icon-connection"></i>
          <span>Scoring</span>
        </el-menu-item>
        <el-menu-item index="form-detail-test" v-if="isEcareCatIrtForm">
          <i class="el-icon-aim"></i>
          <span>Test</span>
        </el-menu-item>
        <!-- NON E-CARE -->
        <el-menu-item index="form-detail-ui-component" v-if="!isEcareCatIrtForm">
          <i class="el-icon-set-up"></i>
          <span>Form Component</span>
        </el-menu-item>
        <el-menu-item index="form-color-setting" v-if="isStandardForm">
          <i class="el-icon-brush"></i>
          <span>Color Setting</span>
        </el-menu-item>
        <el-menu-item index="form-detail-options" v-if="isStandardForm || isStandarCatIrtForm">
          <i class="el-icon-setting"></i>
          <span>Options</span>
        </el-menu-item>
        <el-menu-item index="form-detail-scoring" v-if="isStandardForm || isStandarCatIrtForm">
          <i class="el-icon-cpu"></i>
          <span>Scoring</span>
        </el-menu-item>
        <el-menu-item index="form-custom-validation" v-if="isStandardForm">
          <i class="el-icon-s-release"></i>
          <span>Custom Validation</span>
        </el-menu-item>
        <el-menu-item index="form-detail-contents" v-if="isStandardForm || isStandarCatIrtForm">
          <i class="el-icon-document"></i>
          <span>Content</span>
        </el-menu-item>
        <!-- KONAMI ONLY -->
        <el-menu-item index="form-detail-fields" v-if="showAdvanced">
          <i class="el-icon-connection"></i>
          <span>Fields</span>
        </el-menu-item>
        <el-menu-item index="form-detail-triggers" v-if="showAdvanced">
          <i class="el-icon-aim"></i>
          <span>Triggers</span>
        </el-menu-item>
        <el-menu-item index="form-detail-ui" v-if="showAdvanced">
          <i class="el-icon-view"></i>
          <span>UI Configuration</span>
        </el-menu-item>
        <!-- ACTIONS -->
        <el-menu-item index="form-detail-actions">
          <i class="el-icon-document-checked"></i>
          <span>Actions</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="detail--main">
      <component
        :is="detailComponent"
        v-bind.sync="detailComponentProps"
        @form-action="onFormAction"
      />
    </div>
    <div class="detail--right" v-if="shouldShowFormPreview">
      <form-preview :form="form" />
    </div>

    <el-dialog
      title="Review Changes Before Saving"
      :visible.sync="shouldShowFormChangeDialog"
      width="60%"
      destroy-on-close
    >
      <FormDetailActions
        :shouldShowDeleteAction="shouldShowDeleteAction"
        v-bind.sync="detailComponentProps"
        @form-action="onFormAction"
      />
    </el-dialog>
  </div>
</template>

<script>
import FormDetailBasic from "@/components/Form/FormDetailBasic";
import FormDetailTriggers from "@/components/Form/FormDetailTriggers";
import FormDetailContents from "@/components/Form/FormDetailContents";
import FormDetailUi from "@/components/Form/FormDetailUi";
import FormDetailOptions from "@/components/Form/FormDetailOptions";
import FormDetailFields from "@/components/Form/FormDetailFields";
import FormDetailActions from "@/components/Form/FormDetailActions";
import FormDetailUiComponent from "@/components/Form/FormDetailUiComponent";
import FormColorSetting from "@/components/Form/FormColorSetting";
import FormCustomValidation from "@/components/Form/FormCustomValidation";
import FormDetailNaturalQuestions from "@/components/Form/FormDetailNaturalQuestions";
import FormDetailScoring from "@/components/Form/FormDetailScoring";
import _ from "lodash";
import FormPreview from "@/components/Form/FormPreview";
import { mapGetters } from "vuex";

export default {
  name: "FormDetail",
  components: { FormPreview, FormDetailActions },
  props: {
    form: Object,
    updateHandler: Function,
    deleteHandler: Function,
    generateHandler: Function,
  },
  data() {
    return {
      activeDetailTab: "form-detail-basic",
      originalForm: {},
      detailComponent: FormDetailBasic,
      detailComponentProps: {
        form: { name: "", description: "" },
      },
      shouldShowFormPreview: false,
      shouldShowFormChangeDialog: false,
      shouldShowDeleteAction: false,
    };
  },
  // temp for demo
  computed: {
    ...mapGetters(["showAdvanced"]),
    isEcarePage() {
      return window.location.href.includes("ecare");
    },
    isCatIrtForm() {
      return this.form.settings?.options?.catirt;
    },
    isStandardForm() {
      return !this.isEcarePage && !this.isCatIrtForm;
    },
    isStandarCatIrtForm() {
      return !this.isEcarePage && this.isCatIrtForm;
    },
    isEcareCatIrtForm() {
      return this.isEcarePage && this.isCatIrtForm;
    },
  },
  methods: {
    reportError: function (error) {
      const response = error.response?.data;
      let message = `Error while update form, please try again.`;
      if (response && response.data.code && response.data.reason) {
        message += ` Error code: ${response.data.code}, reason: ${response.data.reason}`;
      }
      this.$alert(message);
    },
    async onFormAction({ action, callback, ...args }) {
      switch (action) {
        case "save":
          try {
            this.shouldShowFormChangeDialog = false;
            await this.updateHandler();
            this.$message.success("Form data has been updated");
            this.setDetailComponentProps();
            this.$eventBus.$emit("reload-form-preview");
            if (callback) {
              callback();
            }
          } catch (error) {
            this.reportError(error);
          }
          break;
        case "delete":
          try {
            await this.deleteHandler();
            this.$message.success("Form data has been deleted");
          } catch (error) {
            this.reportError(error);
          }
          break;
        case "reverted":
          this.$message.success("Form data has been reverted");
          this.saveOriginalForm();
          break;
        case "generate":
          try {
            await this.generateHandler();
            this.$message.success(`Content nodes & triggers has been created`);
          } catch (error) {
            this.reportError(error);
          }
      }
    },
    onMenuSelect(index) {
      const components = [
        FormDetailBasic,
        FormDetailUiComponent,
        FormColorSetting,
        FormDetailOptions,
        FormDetailFields,
        FormDetailTriggers,
        FormDetailContents,
        FormDetailUi,
        FormDetailActions,
        FormCustomValidation,
        FormDetailNaturalQuestions,
        FormDetailScoring,
      ];
      this.detailComponent = components.find((component) => _.kebabCase(component.name) === index);
      const componentName = _.kebabCase(this.detailComponent.name);
      this.shouldShowFormPreview =
        componentName !== "form-detail-basic" && !this.form.settings?.options?.catirt;
    },
    selectDefaultTab() {
      const formDetailBasic = "form-detail-basic";
      this.activeDetailTab = formDetailBasic;
      this.$refs.formMenu.activeIndex = formDetailBasic;
      this.onMenuSelect(formDetailBasic);
    },
    saveOriginalForm() {
      this.originalForm = _.cloneDeep(this.form);
    },
    setDetailComponentProps() {
      this.saveOriginalForm();
      this.detailComponentProps = { form: this.form, originalForm: this.originalForm };
    },
  },
  mounted() {
    this.setDetailComponentProps();
    this.$eventBus.$on("form-save", () => {
      this.shouldShowFormChangeDialog = true;
    });
  },
  watch: {
    "form.id"(val) {
      this.selectDefaultTab();
      this.setDetailComponentProps();
    },
  },
};
</script>

<style scoped lang="scss">
.detail--container {
  display: grid;
  grid-template-areas: "left main right";
  grid-template-columns: 180px 3fr auto;
  height: 100%;
}

.detail--left {
  display: grid;
  grid-area: left;
  width: 180px;

  ::v-deep {
    .el-menu-item.is-active {
      background: rgba(46, 43, 228, 0.1);
    }
  }
}

.detail--main {
  display: grid;
  position: relative;
  grid-area: main;
  padding: 20px;
}

.detail--right {
  width: 400px;
  display: grid;
  position: relative;
  grid-area: right;
  padding: 20px 20px 20px 0;
}
</style>
