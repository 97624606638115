<template>
  <div>
    <el-row style="text-align: center; position: relative" v-if="!forms || forms.length === 0">
      <h4>There is no form yet.</h4>
      <el-button @click="$emit('refresh')" size="medium">Refresh</el-button>
    </el-row>
    <el-row
      v-else
      v-for="form in formToShow"
      :key="form.id"
      effect="dark"
      @click.native="
        $emit('select', form.id);
        selectedId = form.id;
      "
      style="margin-bottom: 4px"
      class="item list-item"
      :class="{ selected: form.id === selectedId }"
    >
      <el-row style="margin-bottom: 0">
        <el-col class="form-item-name">
          {{ form.name }}
        </el-col>
        <el-col class="form-item-id">
          <small>{{ form.id }}</small>
          <el-tag
            style="position: relative; float: right"
            size="small"
            v-if="form.settings?.options?.catirt"
            round
            >cat-irt</el-tag
          >
        </el-col>
      </el-row>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "FormList",
  props: {
    forms: Array,
    selected: String,
    category: String,
  },
  data() {
    return {
      selectedId: "",
    };
  },
  computed: {
    formToShow() {
      if (this.category === "cat-irt")
        return _.filter(this.forms, (form) => _.get(form, "settings.options.catirt", false));

      return this.forms;
    },
  },
  watch: {
    selected() {
      this.selectedId = this.selected;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";
@import "./styles/form";

.form-item-id {
  color: $color-dark;
  white-space: normal;
  font-weight: 400;
  small {
    font-size: 10px;
  }
}

.form-item-name {
  font-size: 1em;
  color: $color-dark;
  margin-top: 5px;
  white-space: normal;
  font-weight: 400;
}
</style>
