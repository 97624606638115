<template>
  <div>
    <div id="es-header">
      <div class="main-title">External Source</div>
    </div>
    <div id="es-body">
      <div class="custom-plugin">
        <div class="plugin-item-wrapper">
          <div class="plugin-item">
            <div class="plugin-header">
              <div class="plugin-info">
                <div class="plugin-img">
                  <img :src="GoogleDriveIcon" alt="" />
                </div>
                <div class="plugin-name">
                  <p>Google Drive</p>
                  <span>Google</span>
                </div>
              </div>
              <div class="plugin-button">
                <el-button type="primary" size="small" @click="showModal()">Connect</el-button>
              </div>
            </div>
            <div class="plugin-body">
              File storage and synchronization service developed by Google.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      GoogleDriveIcon: require("@/views/KnowledgeBase/assets/icons/google-drive.png"),
    };
  },
};
</script>

<style lang="scss">
#es-header {
  margin-bottom: 50px;
}

.es-item {
  border: 1px solid #ebeef5;
  border-radius: 5px;
  padding: 5px;
  display: inline-block;
  text-align: center;
}

#es-body {
  margin-top: 30px;
  .plugin-item-wrapper {
    width: 50%;
    padding: 5px;
    box-sizing: border-box;
    .plugin-item {
      width: 100%;
      border: 1px solid #ebeef5;
      padding: 15px;
      border-radius: 5px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      box-sizing: border-box;

      .plugin-header {
        display: flex;
        justify-content: space-between;
        .plugin-info {
          display: flex;
          .plugin-img {
            width: 50px;
            height: 50px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .plugin-name {
            margin-left: 20px;
            p {
              margin: 0;
              font-weight: 500;
              font-size: 18px;
            }
            span {
              font-size: 14px;
            }
          }
        }
      }
      .plugin-body {
        margin-top: 30px;
      }
    }
  }

  .available-plugin {
    margin-top: 20px;
    p {
      margin-bottom: 10px;
      margin-left: 10px;
      font-size: 24px;
      font-weight: 500;
    }
    .plugin-items {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>
