<template>
  <div style="display: inline-block">
    <el-checkbox
      v-model="value[name]"
      :label="setting.label || name"
      border
      class="has-text-grey"
      style="margin-bottom: 1em; margin-right: 5px"
      :disabled="!modules.webchat.fileUpload"
    />
    <Tooltip :label="setting.label || name" :content="setting.detail" />
  </div>
</template>

<script>
export default {
  props: ["value", "setting", "name", "mode", "modules"],
  data() {
    return {};
  },
  mounted() {
    if (this.value[this.name] == null && typeof this.value === "object") {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
    console.log(this.modules);
  },
};
</script>

<style></style>
