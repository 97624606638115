<template>
  <div>
    <!-- INFORMATION -->
    <el-collapse v-model="activeNames" @change="handleChange" style="margin-bottom: 20px">
      <el-collapse-item title="Instruction" name="1">
        <p>
          "welcome" content is the text that will be displayed to user when form is triggered.
          <br /><br />
          "submit_confirmed" content is the text that will be displayed to user after form is
          finished.
        </p>
      </el-collapse-item>
    </el-collapse>

    <!-- JSON -->
    <advanced-json-editor
      :json.sync="editableJson"
      :height="640"
      @error="onError"
      @updated="onUpdated"
    ></advanced-json-editor>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AdvancedJsonEditor from "./AdvancedJsonEditor";
import _ from "lodash";

export default {
  name: "FormDetailContents",
  components: {
    AdvancedJsonEditor,
  },
  props: {
    form: Object,
  },
  computed: {
    ...mapGetters(["showAdvanced"]),
    isCatIrtMode() {
      return _.get(this.form, "settings.options.catirt", false);
    },
    editableJson() {
      if (this.showAdvanced) return this.form.settings.contents;
      if (this.isCatIrtMode)
        return _.pick(this.form.settings.contents, ["welcome", "submit_confirmed"]);

      return _.pick(this.form.settings.contents, ["welcome", "submit_confirmed", "embedded"]);
    },
  },
  methods: {
    onError() {
      //TODO: show error
    },
    onUpdated(val) {
      this.form.settings.contents = _.cloneDeep({ ...this.form.settings.contents, ...val });
    },
  },
};
</script>

<style scoped></style>
