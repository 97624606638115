<template>
  <div class="tab-contacts-header">
    <el-row>
      <el-col :span="17">
        <div class="tab-contacts-header-date-picker">
          <el-date-picker
            popper-class="tab-contacts-header-date-picker-custom"
            v-model="filterDate"
            type="daterange"
            :picker-options="pickerOptions"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            align="right"
            format="dd MMM"
            @change="changeDatePicker"
          >
          </el-date-picker>
          <div class="tab-contacts-header-date-picker-filter" style="position: relative">
            <el-button
              type="primary"
              plain
              icon="el-icon-s-operation"
              @click="handleFilterModal(true)"
            >
              Filter</el-button
            >
            <FilterContacts
              v-if="isOpenFilter"
              @close-filter="handleFilterModal"
              @apply-filter="onApplyFilter"
            />
          </div>
          <div
            class="tab-contacts-header-date-picker-filter"
            style="margin-left: 10px; position: relative"
          >
            <el-button
              type="primary"
              plain
              icon="el-icon-s-operation"
              @click="handleSortModel(true)"
            >
              Sort</el-button
            >
            <SortContacts
              v-if="isOpenSort"
              @close-sort="handleSortModel"
              @apply-sort="onApplySort"
            />
          </div></div
      ></el-col>
      <el-col :span="7">
        <div class="header-button-container-left">
          <div class="tab-contacts-header-upload">
            <el-button
              plain
              type="primary"
              size="small"
              @click="dialogTableVisible = true"
              icon="el-icon-plus
"
            >
              Add Contacts
            </el-button>

            <el-dialog :visible.sync="dialogTableVisible" :close-on-click-modal="false" width="40%">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="Import" name="import"
                  ><ImportFileContacts
                    :dialogTableVisible="dialogTableVisible"
                    @close-dialog="closeDialog"
                /></el-tab-pane>
                <el-tab-pane label="New Contact" name="newContact"
                  ><CreateNewContact
                    :dialogTableVisible="dialogTableVisible"
                    @close-dialog="closeDialog"
                /></el-tab-pane>
              </el-tabs>
            </el-dialog>
          </div>

          <el-dropdown @command="handleCommand">
            <el-button
              plain
              type="warning"
              size="small"
              class="button-attributes-contact"
              icon="el-icon-edit"
              style="margin-left: 5px"
            >
              Manage Custom Attributes
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-plus" command="AddNewField"
                >Add New Field</el-dropdown-item
              >
              <el-dropdown-item icon="el-icon-view" command="HideShowField"
                >Hide/Show Field</el-dropdown-item
              >
              <el-dropdown-item icon="el-icon-document" command="BulkAssignValue"
                >Bulk Assign Value</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>

      <contact-attributes-dialog
        :visible.sync="visibleDialogAttributes"
        @refetch-data="refreshDataAndCloseAttributeDialog"
      />
      <ContactAttributesOnClientDialog
        :visible.sync="visibleDialogCustomFieldOnClient"
        @refetch-data="refreshDataAndCloseCustomFieldOnClientDialog"
      />
      <ContactAttributesAssignValue
        :visible.sync="visibleDialogAssignValue"
        @refetch-data="refreshDataAndCloseAssignValueDialog"
      />
    </el-row>
  </div>
</template>

<script>
import { trendingFilterShortcuts } from "@/helperMethods/trendingTopics/util";
import ImportFileContacts from "./ImportFileContacts.vue";
import FilterContacts from "./FilterContacts.vue";
import SortContacts from "./SortContacts.vue";
import FilterIcon from "../assets/filter_icon.svg";
import PlusIcon from "../assets/icon_plus.svg";
import EditIcon from "../assets/edit_icon.svg";
import ContactAttributesDialog from "./ContactAttributesDialog.vue";
import ContactAttributesOnClientDialog from "./ContactAttributesOnClientDialog.vue";
import ContactAttributesAssignValue from "./ContactAttributesAssignValue.vue";
import CreateNewContact from "./CreateNewContact.vue";

export default {
  components: {
    ImportFileContacts,
    FilterContacts,
    ContactAttributesDialog,
    CreateNewContact,
    ContactAttributesOnClientDialog,
    ContactAttributesAssignValue,
    SortContacts,
  },
  data() {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 6);
    return {
      // date picker options
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: trendingFilterShortcuts.map((item, index) => ({
          ...item,
          onClick: (picker) => {
            item.onClick(picker), (this.indexShortcut = index);
          },
        })),
      },
      filterDate: [start, end],
      indexShortcut: 6,
      // dialog options
      dialogTableVisible: false,
      activeName: "import",
      // filter options
      isOpenFilter: false,
      isOpenSort: false,
      // icon src
      FilterIcon,
      PlusIcon,
      EditIcon,
      // dialog attributes
      visibleDialogAttributes: false,
      visibleDialogCustomFieldOnClient: false,
      visibleDialogAssignValue: false,
      currentAttributes: {
        id: undefined,
        attributes: [],
      },
    };
  },

  mounted() {
    this.handlerPickedDatePicker();
    this.changeDatePickerIcon();
  },
  methods: {
    handleVisibleDialogAttributes(data) {
      this.visibleDialogAttributes = true;
      // this.currentAttributes = {
      //   id: data.id,
      //   attributes: data.outreachcontactsattributes || [],
      // };
    },
    handleCustomFieldVisibleDialogAttributes(data) {
      this.visibleDialogCustomFieldOnClient = true;
    },
    changeDatePickerIcon() {
      document.querySelector(
        ".tab-contacts-header-date-picker .el-range-separator"
      ).innerHTML = `<i class="el-icon-right" style="font-size:15px;font-weight:bold;"></i>`;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleFilterModal(isOpen = false) {
      this.isOpenFilter = isOpen;
    },
    handleSortModel(isOpen = false) {
      this.isOpenSort = isOpen;
    },
    onApplyFilter(filterList) {
      this.$emit("apply-filter", filterList);
    },
    onApplySort(sortAttribute) {
      this.$emit("apply-sort", sortAttribute);
    },
    closeDialog(importSuccess) {
      this.dialogTableVisible = false;
      if (importSuccess) {
        this.$emit("refetch-contact");
      }
    },
    changeDatePicker(e) {
      console.log(e);
    },
    handlerPickedDatePicker() {
      const shortcuts = document.getElementsByClassName("el-picker-panel__shortcut");
      const length = shortcuts.length;
      for (let i = 0; i < length; i++) {
        shortcuts[i].style.color = i === this.indexShortcut ? "#1d57d8" : "gray";
      }
    },
    refreshDataAndCloseAttributeDialog() {
      this.visibleDialogAttributes = false;
      this.$store.dispatch("FETCH_CONTACT_ATTRIBUTES");
      this.$emit("refetch-contact");
    },
    refreshDataAndCloseCustomFieldOnClientDialog() {
      this.visibleDialogCustomFieldOnClient = false;
      this.$store.dispatch("FETCH_CONTACT_ATTRIBUTES");
      this.$emit("refetch-contact");
    },
    refreshDataAndCloseAssignValueDialog() {
      this.visibleDialogAssignValue = false;
      this.$emit("refetch-contact");
    },
    handleCommand(command) {
      if (command === "AddNewField") {
        this.handleVisibleDialogAttributes();
      } else if (command === "HideShowField") {
        this.handleCustomFieldVisibleDialogAttributes();
      } else if (command === "BulkAssignValue") {
        this.visibleDialogAssignValue = true;
      }
    },
  },
  watch: {
    filterDate: {
      handler() {
        this.$emit("apply-filter-date", this.filterDate);
      },
    },
    indexShortcut: {
      handler() {
        this.handlerPickedDatePicker();
      },
    },
  },
};
</script>

<style lang="scss">
$base-date-picker-color: #a5bcef;
$button-bg-hover: #e8eefb;
$button-border-hover: #1d57d8;
// $button-border-hover: #bbcdf3;

.el-date-range-picker__time-header {
  display: none !important;
}
.el-date-editor {
  .el-range__icon {
    display: none !important;
  }
  &:hover {
    background: $button-bg-hover !important;
    border-color: $button-border-hover !important;
    > * {
      background: $button-bg-hover !important;
      cursor: pointer;
    }
    > * {
      line-height: 16px;
    }
  }
}

.tab-contacts-header {
  &-date-picker {
    display: flex;
    text-align: center;
    &-filter {
      > button {
        padding: 8px 15px;
      }
    }
    .el-range-editor.el-input__inner {
      width: 150px;
      height: 31px;
      display: none;
      justify-content: center;
      border: 1px solid $base-date-picker-color !important;
      padding: 3px 6px !important;
    }
    .el-range-input {
      color: #1d57d8 !important;
      font-size: 12px !important;
    }
    .el-range-separator {
      font-size: 20px !important;
      color: $base-date-picker-color !important;
      width: fit-content !important;
      line-height: 27px !important;
      display: flex !important;
      align-items: center !important;
    }
    &-custom {
      left: 50% !important;
      transform: translateX(-60%) !important;
      .popper__arrow {
        display: none !important;
      }
    }
    .el-date-editor .el-range__close-icon {
      display: none !important;
    }
  }
}
.header-button-container-left {
  display: flex;
  justify-content: flex-end;

  .el-button {
    padding: 8px !important;
    text-transform: capitalize;
  }
}
</style>
