<template>
  <el-row v-if="showPreview" style="margin: 0; padding: 10px 0px">
    <el-card
      style="position: relative; padding: 0"
      shadow="never"
      @click.native="onClickCard()"
      :body-style="{ padding: '12px 24px', cursor: 'pointer' }"
    >
      <i
        class="el-icon-close close-icon"
        @click="onClose()"
        v-if="!message"
      ></i>
      <div class="content-container">
        <div class="preview-text">
          <div v-if="title.length > 0">
            <b>{{ title }}</b
            ><br />
          </div>
          <span v-if="reply.type === 'text'">{{ previewContent }}</span>
          <div v-else-if="reply.type === 'image'">
            <i class="el-icon-camera-solid pre-icon"></i><span>Image</span>
          </div>
          <div v-else-if="reply.type === 'file'">
            <i class="el-icon-notebook-2 pre-icon"></i>
            <span>{{ previewContent }}</span>
          </div>
        </div>
        <div
          v-if="!!previewUrl"
          class="preview-image"
          :style="{
            backgroundImage: `url(${previewUrl})`,
          }"
        ></div>
      </div>
    </el-card>
  </el-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PreviewReply",
  props: {
    message: Object,
  },
  data() {
    return {
      previewUrl: "",
    };
  },
  computed: {
    ...mapGetters(["replyObj"]),
    showPreview() {
      return (!!this.replyObj?.id && !this.message) || !!this.message?.data?.context?.id;
    },
    reply() {
      if (!this.message) {
        return this.replyObj;
      }
      const contextId = this.message?.data?.context?.id;
      const context = this.message?.data?.contextPayload;
      if (contextId && !context) {
        return {
          id: null,
          key_id: null,
          type: "text",
          from: "",
          previewContent: "Reply content is not available for this message.",
        };
      }
      const from =
        (context?.type === "message" || context?.type === "postback"
          ? "User"
          : context?.type === "reply"
          ? "Bot"
          : _.capitalize(context?.type)) || "N/A";
      const type = context?.data?.content?.[0]?.images
        ? "image"
        : context?.data?.content?.[0]?.files
        ? "file"
        : "text";
      let previewContent =
        type === "text"
          ? context?.data?.content?.[0]?.text
          : "";
      if (type === "file") {
        const fileItem = context?.data?.content?.[0]?.files?.[0] || {};
        const { url = fileItem } = fileItem;
        previewContent = url.split("/").pop();
      }
      if (type === "image") {
        const imgItem = context?.data?.content?.[0]?.images?.[0] || {};
        const { url = imgItem } = imgItem;
        this.getPreviewUrl(url);
      }
      const replyObj = {
        id: context?.RowKey || null,
        key_id: context?.key_id,
        type,
        from,
        previewContent,
      };
      return replyObj;
    },
    title() {
      if (!this.message) {
        return `Reply to: ${this.reply.from}`;
      }
      return this.reply.from;
    },
    previewContent() {
      if (this.title.length === 0) {
        return this.reply.previewContent;
      } else if (!this.message) {
        return _.truncate(this.reply.previewContent, { length: 96 });
      }
      return _.truncate(this.reply.previewContent);
    },
  },
  methods: {
    onClickCard() {
      this.$emit("updatePulseRowKey", this.reply.id);
    },
    onClose() {
      this.$store.commit("SET_REPLY");
    },
    async getPreviewUrl(blobUrl) {
      if (!blobUrl) {
        return;
      }
      this.previewUrl = await this.$store.dispatch("FETCH_FILE_URL", blobUrl);
    },
  },
};
</script>

<style scoped lang="scss">
  .pre-icon {
    margin-right: 4px;
  }
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .content-container {
    display: flex;
    align-items: center;
  }
  .preview-text {
    font-size: 0.8rem;
  }
  .preview-image {
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-left: 10px;
    background-size: cover;
    background-position: center;
    height: 50px;
  }
</style>
