<template>
  <div id="kb-status-modal">
    <el-dialog
      title="Document Status"
      :visible.sync="statusDialogVisible"
      width="90%"
      :before-close="handleClose"
    >
      <div style="font-size: 16px">
        <div class="status">
          <div style="display: flex; justify-content: space-between">
            <div>
              <span>Status: </span>
              <div style="display: inline">
                <span class="not-completed">Not Complete</span>
              </div>
            </div>
          </div>
        </div>
        <div class="step">
          <div style="margin-bottom: 20px">Progress:</div>
          <el-steps :active="activeStep" finish-status="success">
            <el-step v-for="(step, index) in steps" :key="index" :title="step"></el-step>
          </el-steps>
        </div>
        <div v-if="this.isWebsite" style="margin-top: 30px; font-size: 16px">
          Note: Upload is needed once the scraping is completed to continue the process.
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    statusDialogVisible: {
      type: Boolean,
      default: false,
    },
    selectedDocumentStatus: {
      type: Object,
      default: null,
    },
    isRefreshingStatus: {
      type: Boolean,
      default: false,
    },
    isWebsite: {
      type: Boolean,
      default: false,
    },
    selectedWebsite: {
      type: Object,
      default: null,
    },
  },
  computed: {
    activeStep() {
      if (this.selectedWebsite) {
        const isScraping = this.selectedWebsite.isScraping;
        if (isScraping) {
          return 1;
        }
        return 2;
      }
      return 0;
    },
    steps() {
      if (this.isWebsite) {
        return [
          "Scraping Started",
          "Scraping Completed",
          "Extract",
          "Partition",
          "Gen Embeddings",
          "Save Embeddings",
          "Summarize",
          "Gen Embeddings",
          "Save Embedding",
        ];
      }
      return [
        "Upload Document",
        "Extract",
        "Partition",
        "Gen Embeddings",
        "Save Embeddings",
        "Summarize",
        "Gen Embeddings",
        "Save Embedding",
      ];
    },
  },
  methods: {
    handleClose() {
      this.$emit("updateStatusDialogVisible", false);
    },
  },
};
</script>

<style lang="scss">
#kb-status-modal {
  .completed {
    color: green;
  }

  .not-completed {
    color: red;
  }

  .step {
    margin-top: 10px;
  }

  .status {
    margin: 10px 0;

    .refresh-button {
      margin-left: 10px;
      &.active {
        animation: spin 2s linear infinite;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
</style>
