<template>
  <el-row>
    <div class="import-file">
      <div class="import-file-download">
        <el-button size="mini" type="text" @click="downloadTemplate" class="download-template">
          Download CSV Template
        </el-button>
      </div>
      <div class="import-file-upload">
        <el-row :gutter="10">
          <el-col>
            <el-upload
              drag
              class="upload-demo"
              action="#"
              :before-upload="beforeUpload"
              :on-success="handleSuccess"
              :on-remove="handleRemove"
              :on-change="handleChange"
              :file-list="fileList"
              :disabled="isDisabled"
            >
              <div class="el-upload__text file-upload-inner-text">
                <!-- IF FILE SELECTED -->
                <div v-if="fileList[0]" class="upload-file-result">
                  <div class="upload-file-result-container">
                    <img :src="UploadSuccessIcon" alt="" v-if="checkSuccessUploadFile()" />
                    <img :src="UploadErrorIcon" alt="" v-else />
                    <div class="upload-file-result-container-name">
                      <p>
                        {{ fileList[0].name }}
                      </p>
                      <el-button type="text" @click="handleRemove">
                        <i class="el-icon-close"></i>
                      </el-button>
                    </div>
                    <p v-if="!checkSuccessUploadFile()" style="color: red; margin: 0">Error</p>
                  </div>
                </div>
                <!-- IF ASKING USER TO ULOAD -->
                <div v-else class="upload-file-drag">
                  <div class="upload-file-drag-upload">
                    <img :src="FileUploadIcon" alt="" />
                    <p style="color: black; margin: 0">Drag or select file</p>
                  </div>
                  <div class="upload-file-drag-button">
                    <button>Choose File</button>
                    <p>(.CSV)</p>
                  </div>
                </div>
              </div>
            </el-upload>
          </el-col>
        </el-row>
      </div>
      <div class="import-file-notes">
        <div class="button-import">
          <el-button
            size="small"
            @click="submitUpload"
            plain
            type="primary"
            v-show="checkSuccessUploadFile()"
            >Import</el-button
          >
        </div>
        <div class="import-file-notes-title" v-show="!checkSuccessUploadFile()">
          <img :src="InstructionsIcon" alt="" />
          <p>Instructions</p>
        </div>
        <div
          v-for="(note, i) in notes"
          :key="i"
          class="import-file-notes-note"
          v-show="!checkSuccessUploadFile()"
        >
          <p>- {{ note }}</p>
        </div>
      </div>
    </div>
  </el-row>
</template>

<script>
import Vue from "vue";
import XLSX from "xlsx";
import UploadSuccessIcon from "@/views/Outreach/assets/upload_success_icon.svg";
import UploadErrorIcon from "@/views/Outreach/assets/upload_error_icon.svg";
import FileUploadIcon from "@/views/Outreach/assets/file_upload_icon.svg";
import InstructionsIcon from "@/views/Outreach/assets/instructions_icon.svg";
import DotIcon from "@/views/Outreach/assets/dot_icon.svg";

export default Vue.extend({
  props: {
    dialogTableVisible: Boolean,
    form: Object,
  },
  data() {
    return {
      fileList: [],
      err: undefined,
      notes: [
        "Download the template file and add CAT IRT questions there",
        "Do not change the column header",
        "Delete the example row once the purpose is served",
        "This will overwrite all fields already existed here",
      ],
      requiredColumns: [
        "code",
        "question",
        "option1",
        "option2",
        "option3",
        "option4",
        "option5",
        "a1",
        "a2",
        "a3",
        "a4",
        "a5",
        "b1",
        "b2",
        "b3",
        "b4",
        "b5",
        "question_a",
      ],
      isDisabled: false,
      // icon
      UploadSuccessIcon,
      UploadErrorIcon,
      FileUploadIcon,
      InstructionsIcon,
      DotIcon,
    };
  },
  methods: {
    checkSuccessUploadFile() {
      return this.fileList[0] && this.fileList[0].status !== "error" && !this.err;
    },
    beforeUpload(file) {
      const isCSV = file.type === "text/csv" || file.name.endsWith(".csv");
      if (isCSV) {
        this.handleSuccess("", file);
      } else {
        this.$message.error("Uploaded file must be in CSV format!");
      }
      return isCSV;
    },
    handleError(err) {
      console.log(err);
      this.err = err;
    },

    handleRemove() {
      this.fileList = [];
      this.isDisabled = true;
      setTimeout(() => {
        this.isDisabled = false;
      }, 1000);
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    handleSuccess(res, file) {
      this.uploadedData = [];

      const reader = new FileReader();
      reader.onload = ((vuecomponent) => {
        return (e) => {
          const data = e.target.result;
          const wb = XLSX.read(data, {
            type: "binary",
            raw: true,
          });
          const firstSheetName = wb.SheetNames[0];
          const firstSheet = wb.Sheets[firstSheetName];
          this.uploadedData = XLSX.utils.sheet_to_json(firstSheet);
          let isError = false;

          _.each(this.uploadedData, (row, idx) => {
            if (isError) return false;
            _.each(this.requiredColumns, (column) => {
              if (_.isEmpty(row[column])) {
                this.$message.error(`Missing value on column ${column} on row ${idx}`);
                isError = true;
                return false;
              }
            });
          });

          this.err = isError;
        };
      })(this);
      reader.readAsText(file);
      this.fileList = [...this.fileList, file];
    },
    downloadTemplate() {
      const link = document.createElement("a");
      link.download = "form-import-catirt-template.csv";
      link.href = "/template/form-import-catirt-template.csv";
      link.click();
    },
    // convert to form json
    submitUpload() {
      const properties = {},
        uiSchema = {
          "ui:order": [],
        };

      _.each(this.uploadedData, (row) => {
        // build properties
        const enums = [];
        const enumNames = [];
        const catIrtOptions = [];

        _.times(5, (idx) => {
          catIrtOptions.push({});

          const _a = row[`a${idx + 1}`];
          const _b = row[`b${idx + 1}`];

          _a && _.set(catIrtOptions, `[${idx}].a`, this.parseFloat(_a));
          _b && _.set(catIrtOptions, `[${idx}].b`, this.parseFloat(_b));

          const enumColumn = `option${idx + 1}`;
          row[enumColumn] && enumNames.push(row[enumColumn]) && enums.push(idx);
        });

        properties[row.code] = {
          title: row.question,
          type: "string",
          description: "",
          enum: enums,
          enumNames,
          kr_options: {
            catirt: {
              options: catIrtOptions,
              a: row.question_a ? this.parseFloat(row.question_a) : "",
              b: row.question_b ? this.parseFloat(row.question_b) : "",
            },
          },
        };

        // build uiSchema
        uiSchema["ui:order"].push(row.code);
        uiSchema[row.code] = {
          "ui:widget": "radio",
          "ui:readonly": false,
        };
      });

      this.$set(this.form.settings, "properties", properties);
      this.$set(this.form.settings, "uiSchema", uiSchema);

      this.$notify.success({
        title: "Success",
        position: "bottom-right",
        message: `Import successful.`,
      });
      this.$emit("close-dialog", { properties, uiSchema });
    },
    parseFloat(val) {
      try {
        const replacedCommaWithDot = val.replace(",", ".");
        const floatWithTwoDecimals = parseFloat(replacedCommaWithDot).toFixed(2);
        const result = Number(floatWithTwoDecimals); // Number needed because toFixed return a string
        return result;
      } catch (e) {
        console.error(e);
        return Number(val);
      }
    },
  },
  watch: {
    dialogTableVisible: {
      handler() {
        this.handleRemove();
      },
    },
  },
});
</script>

<style lang="scss">
$color-drag-file: #1d57d8;
$button-bg-hover: #e8eefb;

.import-file {
  button {
    cursor: pointer;
  }
  color: #409eff !important;

  &-download {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
    .download-template {
      border: none !important;
      &:hover {
        background: none !important;
      }
      > span {
        font-weight: 400 !important;
        font-size: 8px !important;
      }
    }
  }

  .file-upload-inner-text {
    height: 200px;
    .upload-file-drag {
      display: flex !important;
      flex-direction: column !important;
      justify-content: space-between !important;
      height: 100%;
      &-upload {
        > p {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: #000000;
        }
      }
      &-button {
        > p {
          font-weight: 400;
          font-size: 10px;
          line-height: 11px;
          color: #000000;
          margin: 7 0 0 0;
        }
      }
    }
    color: $color-drag-file !important;
    button {
      background: none !important;
      border: 1px solid $color-drag-file;
      border-radius: 4px;
      padding: 7px 10px;
      color: $color-drag-file !important;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    .upload-file-result {
      &-container {
        margin: auto;
        > i {
          font-size: 30px;
        }
        &-name {
          display: flex;
          color: black;
          > p {
            font-size: 15px;
            font-weight: 500 !important;
          }
          i {
            color: black;
          }
          > button {
            border: none;
            width: fit-content;
            height: fit-content;
          }
        }
      }
    }
  }
  .import-file-upload {
    .el-upload-dragger {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 40px;
      background: #e3ebfa;
      width: 100%;
      border: 1px dashed $color-drag-file !important;
      margin: 20px 0;
    }
    .el-upload-list {
      display: none !important;
    }
  }
  .import-file-notes {
    .button-import {
      position: absolute;
      bottom: 0;
      right: 0;
      > button {
        border: 1px solid $color-drag-file;
        color: $color-drag-file;
        &:hover {
          background: $button-bg-hover !important;
          border-color: $color-drag-file !important;
        }
      }
    }
    height: 150px;
    p {
      margin: 0;
      font-style: normal;
      color: #000000;
    }
    > div {
      display: flex;
    }
    &-title {
      > img {
        margin-right: 9px;
      }
      > p {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
      }
      margin-bottom: 13px;
    }
    &-note {
      padding: 0 5px;
      > img {
        margin-right: 13px;
      }
      > p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        /* or 164% */
      }
    }
  }
}
</style>
