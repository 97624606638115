import { OutreachState } from "./types";

const state: OutreachState = {
  contacts: {
    data: [],
    count: 0,
  },
  stages: {
    data: [],
  },
  tags: {
    data: [],
  },
  metrics: {},
  // metrics: {
  //   1: {
  //     id: 1,
  //     name: 'Active Patients',
  //     value: 23,
  //     color: 'blue',
  //     filterList: {
  //       0: {
  //         variable: "Country",
  //         comparison: "is",
  //         value: "Singapore",
  //         valueArray: [],
  //       },
  //       1:{
  //         variable: "Stage",
  //         comparison: "is",
  //         value: "Stage B",
  //         valueArray: [],
  //       }
  //     },
  //   },
  //   2: {
  //     id: 2,
  //     name: 'Patients need attention',
  //     value: 8,
  //     color: 'red',
  //     filterList: {
  //       0: {
  //         variable: "Tags",
  //         comparison: 'is',
  //         value: "tag1",
  //         valueArray: [],
  //       }
  //     },
  //   },
  // },
  isLoading: false,
  filterBoard: [],
  sortBoard: [],
  contactAttributes: [],
};

export default state;
