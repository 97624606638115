<template>
  <div id="gen-ai-wrapper">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Documents" name="documents"> <Documents /> </el-tab-pane>
      <el-tab-pane label="Articles" name="articles"> <Articles /> </el-tab-pane>
      <el-tab-pane label="Websites" name="website"> <Websites /> </el-tab-pane>
      <el-tab-pane label="External Source" name="external_source"> <ExternalSource /> </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Documents from "./Documents/Index.vue";
import Articles from "./Articles/Index.vue";
import ExternalSource from "./ExternalSource/Index.vue";
import History from "./History/Index.vue";
import Websites from "./Websites/Index.vue";
export default {
  components: { Documents, Articles, Websites, ExternalSource },
  data() {
    return {
      activeTab: "documents",
    };
  },
  mounted() {
    // this.$store.dispatch("GENAI_GET_MODULES");
  },
};
</script>
