<template>
  <div>
    <el-table :data="tableData" class="form-table">
      <el-table-column prop="key" label="Key">
        <template slot-scope="scope">
          <el-input v-model="scope.row.key" placeholder="Key" @input="updateBody"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="value" label="Value">
        <template slot-scope="scope">
          <el-input v-model="scope.row.value" placeholder="Value" @input="updateBody"></el-input>
        </template>
      </el-table-column>
      <el-table-column width="80">
        <template slot-scope="scope">
          <el-button
            :id="`delete-param-button-${scope.$index}`"
            type="danger"
            icon="el-icon-delete"
            size="mini"
            @click="removeBodyParam(scope.$index)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-button
      id="add-param-button"
      type="primary"
      size="mini"
      style="margin-top: 10px"
      @click="addBodyParam"
      >Add Parameter</el-button
    >
  </div>
</template>

<script>
import qs from "query-string";
import _ from "lodash";

const defaultTableDataRow = { key: "", value: "" };
const MAX_TABLE_DATA_LENGTH = 500; // Maximum number of rows

export default {
  props: ["body"],
  data() {
    return {
      tableData: [],
      queryString: this.body,
    };
  },
  mounted() {
    this.tableData = this.convertQueryStringToTableData();
  },
  methods: {
    convertQueryStringToTableData() {
      try {
        const parsedBody = qs.parse(this.queryString);
        const tableDataArray = _.reduce(
          parsedBody,
          (result, value, key) => {
            if (!_.isNil(value)) {
              result.push({ key, value: value.toString() });
            }
            return result;
          },
          []
        );
        return _.isEmpty(tableDataArray) ? [{ ...defaultTableDataRow }] : tableDataArray;
      } catch {
        return [{ ...defaultTableDataRow }];
      }
    },
    convertTableDataToQueryString() {
      const paramsObject = this.tableData.reduce((obj, item) => {
        if (_.has(item, "key") && _.has(item, "value")) {
          if (_.has(obj, item.key)) {
            obj[item.key] = [].concat(obj[item.key], item.value);
          } else {
            obj[item.key] = item.value;
          }
        }
        return obj;
      }, {});
      this.queryString = qs.stringify(paramsObject, { encode: false });
    },
    updateBody: _.debounce(function () {
      this.updateTableData();
    }, 1000),
    updateTableData() {
      this.convertTableDataToQueryString();
      this.$emit("update:body", this.queryString);
    },
    addBodyParam() {
      if (this.tableData.length < MAX_TABLE_DATA_LENGTH) {
        this.tableData.push({ ...defaultTableDataRow });
        this.updateBody();
      } else {
        this.$notify.warning({
          title: "Unable to add more rows",
          message: `Maximum limit of ${MAX_TABLE_DATA_LENGTH} rows reached.`,
          position: "bottom-right",
        });
      }
    },
    removeBodyParam(index) {
      this.tableData.splice(index, 1);
      this.updateBody();
    },
  },
};
</script>

<style scoped>
.form-table {
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.form-table .el-table-column {
  border-right: 1px solid #ddd;
}

.form-table .el-input {
  width: 100%;
}

.form-table .el-table__header {
  font-size: 0.8em;
}
</style>
