<template>
  <el-dialog
    title="Assign Attribute Value to Contacts"
    :visible.sync="dialogVisible"
    width="35%"
    :close-on-click-modal="false"
  >
    <el-form label-position="top">
      <el-form-item label="Field">
        <el-select v-model="attributeId" placeholder="Select" style="width: 100%">
          <el-option
            v-for="item in contactAttributes"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Assign To">
        <el-select v-model="assignTo" placeholder="Select" style="width: 100%">
          <el-option key="all" value="all" label="All Contacts"></el-option>
          <el-option
            key="filtered"
            value="filtered"
            label="Currently Filtered Contacts"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Value">
        <el-input placeholder="Value" v-model="assignedValue" style="width: 100%"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">Cancel</el-button>
      <el-button type="primary" :disabled="enableConfirmButton()" @click="handleConfirm"
        >Confirm</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import gql from "graphql-tag";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      attributeId: "",
      assignedValue: "",
      assignTo: "all",
    };
  },
  methods: {
    enableConfirmButton() {
      return !this.attributeId || !this.assignedValue;
    },
    handleConfirm() {
      if (this.attributeId && this.assignedValue) {
        this.saveAttribute();
      }
    },
    async saveAttribute() {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`
            mutation ($attributeId: Int, $value: String, $filter: [JSON], $all: Boolean) {
              outReachContactAPI {
                assignAttributeValueToContacts(
                  attributeId: $attributeId
                  value: $value
                  filter: $filter
                  all: $all
                )
              }
            }
          `,
          variables: {
            attributeId: this.attributeId,
            value: this.assignedValue,
            filter: this.contactFilterBoard,
            all: this.assignTo === "all" ? true : false,
          },
        });

        this.$emit("refetch-data", true);

        this.$notify.success({
          title: "Success",
          position: "bottom-right",
          message: "Attribute value has been assigned to contacts successfully",
        });
      } catch (error) {
        this.$emit("refetch-data", true);

        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message,
        });
      }
    },
  },
  computed: {
    ...mapGetters(["contactAttributes", "contactFilterBoard"]),
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(data) {
        this.$emit("update:visible", data);
      },
    },
  },
};
</script>
