<template>
  <div>
    <div>
      <div style="margin-bottom: 10px">Persona</div>
      <el-select v-model="value.data.value" placeholder="Select">
        <el-option
          v-for="(item, index) in sortedPersonaList"
          :key="index"
          :label="item.name"
          :value="item.name"
        >
        </el-option>
      </el-select>
    </div>
    <div style="margin-top: 20px">
      <EventNodeSelectorNext v-model="value" />
    </div>
  </div>
</template>

<script>
import { DEFAULT_PERSONA_NAME } from "@/views/GenAI/constants";
import EventNodeSelectorNext from "../Next.vue";
import _ from "lodash";

export default {
  name: "EventNodeTypeCustomEvent",
  components: { EventNodeSelectorNext },
  props: ["value"],
  data() {
    return {
      sortedPersonaList: [],
    };
  },
  computed: {
    modules: {
      get() {
        return this.$store.state.modules;
      },
      set(value) {
        this.$store.state.modules = value;
      },
    },
  },
  methods: {
    sortArray(arr) {
      return arr.sort((a, b) => {
        if (a.name === DEFAULT_PERSONA_NAME) {
          return -1;
        }
        if (b.name === DEFAULT_PERSONA_NAME) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      });
    },
  },
  created() {
    this.sortedPersonaList = this.sortArray(this.modules.genai.personas);
  },
};
</script>
