<template>
  <el-container>
    <el-col :span="4">
      <div class="title">Modules</div>
      <div class="list">
        <el-menu default-active="core" @select="selectModule">
          <el-menu-item v-for="mod in modules" :key="mod.name" :index="mod.name">
            {{ mod.name }}
          </el-menu-item>
        </el-menu>
      </div>
    </el-col>
    <el-col :span="8">
      <div class="title">Functions</div>
      <div class="list">
        <el-menu default-active="1" @select="selectFunction">
          <el-menu-item v-for="func in functions" :key="func.name" :index="func.name">
            <el-checkbox :checked="func.selected">{{ func.name }}</el-checkbox>
          </el-menu-item>
        </el-menu>
      </div>
    </el-col>
    <el-col :span="12">
      <div class="title">Description</div>
      <el-card>
        <div>{{ selectedModule?.description }}</div>
      </el-card>
      <!-- <codemirror class="code" v-model="code" :options="editorOptions"></codemirror> -->
    </el-col>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      selectedModuleId: "Core",
      selectedFunctionId: "",
      functions: [],
    };
  },
  computed: {
    settings() {
      return this.$store.state.modules.genai;
    },
    modules() {
      return this.$store.state.genai.modules;
    },
    selectedModule() {
      return this.$store.state.genai.modules[this.selectedModuleId];
    },
    // functions() {
    //   return this.$store.state.genai.modules[this.selectedModuleId]?.functions;
    // },
    // selectedFunction() {
    //   return this.selectedModule?.functions[this.selectedFunctionId];
    // },
    moduleDescription() {
      return this.$store.state.genai.modules[this.selectedModuleId]?.description;
    },
    functionDescription() {
      return this.$store.state.genai.modules[this.selectedModuleId]?.functions[0]?.description;
    },
    selectedFunctionIds() {
      return this.$store.state.genai.selectedGenaiFunctionIds;
    },
  },
  methods: {
    selectModule(key) {
      this.selectedModuleId = key;
      const selectedModule = this.$store.state.genai.modules[key];
      this.functions = Object.values(selectedModule.functions).map((func) => ({
        ...func,
        selected: true,
      }));
    },
    selectFunction(key) {
      this.selectedFunctionId = key;
    },
    setCheckbox(func) {
      console.log({ func });
    },
    setCheckbox(func) {
      console.log({ func });
    }
  },
};
</script>

<style>
/* .el-tabs {
  height: 100%;
}

.el-tabs__content {
  height: 100%;
}

.el-container {
  height: 100%;
}

.title {
  font-size: 14px;
  margin: 4px;
}

.list {
  border-color: #ddd;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  margin: 4px;
  height: 100%;
}

.code {
  margin: 4px
}

.function-list  {
  display: flex;
  flex-direction: column;
} */
</style>
