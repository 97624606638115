<template>
  <el-dialog
    :visible.sync="addArticleDialogVisible"
    width="60%"
    :before-close="handleClose"
    top="10px"
    title="Add Article"
    :destroy-on-close="true"
  >
    <el-form label-position="top" @submit.prevent="">
      <el-form-item label="Title">
        <el-input v-model="title" placeholder="Title" />
      </el-form-item>
      <el-form-item label="Category" v-if="document_tags.length > 0">
        <div v-for="(item, index) in document_tags" :key="index">
          <el-checkbox
            :label="item.name"
            @change="handleTagChange(item.name, $event)"
          ></el-checkbox>
          <div v-if="isSelectedTagContains(item.name)">
            <el-select placeholder="Select tags..." multiple="" v-model="selectedTags[item.name]">
              <el-option
                v-for="(tag, index) in item.tags"
                :key="index"
                :label="tag"
                :value="tag"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div>
          <el-button size="mini" type="primary" icon="el-icon-plus" @click="handleAddTag"
            >New Tag</el-button
          >
          <div class="tag-wrapper" v-for="(kb_tag, index) in newTags" :key="index">
            <el-form label-position="top">
              <el-form-item>
                <div style="display: flex; justify-content: space-between">
                  <div>Category</div>
                  <div>
                    <el-button
                      style="margin-left: auto"
                      type="danger"
                      size="mini"
                      icon="el-icon-delete"
                      circle
                      @click.prevent="handleDeleteTagObject(index)"
                    ></el-button>
                  </div>
                </div>
                <el-input
                  v-model="kb_tag.name"
                  placeholder="Tag Category"
                  @input="(val) => (kb_tag.name = kb_tag.name.toLowerCase().replace(/\s/g, '_'))"
                />
              </el-form-item>
              <el-form-item label="Tags">
                <el-tag
                  :key="index"
                  v-for="(tag, index) in kb_tag.tags"
                  closable
                  :disable-transitions="false"
                  @close="handleCloseTag(kb_tag, index)"
                  style="margin-left: 10px"
                >
                  {{ tag }}
                </el-tag>
                <el-input
                  class="input-new-tag"
                  v-if="kb_tag.inputVisible"
                  v-model="kb_tag.newTagValue"
                  size="mini"
                  @keyup.enter.native="handleInputConfirm(kb_tag)"
                  @input="(val) => (kb_tag.newTagValue = kb_tag.newTagValue.toLowerCase())"
                  @blur="handleInputConfirm(kb_tag)"
                >
                </el-input>
                <el-button
                  style="margin-left: 10px"
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="showInput(index)"
                  >+ New Tag</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose" :loading="isArticleDialogLoading">Cancel</el-button>
      <el-button @click="handleCreateArticle" type="primary" :loading="isArticleDialogLoading"
        >Add</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { v4 as uuid } from "uuid";
export default {
  data() {
    return {
      title: "",
      selectedTags: {},
      newTags: [],
      articles: JSON.parse(localStorage.getItem("articles")) || [],
    };
  },
  props: {
    addArticleDialogVisible: {
      type: Boolean,
      default: false,
    },
    isArticleDialogLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClose(forceClose = false) {
      if (forceClose) {
        this.$emit("updateEditorVisible", { dialog: "addArticle", status: false });
        return;
      }
      this.$confirm("Are you sure to close this dialog?").then(() => {
        this.$emit("updateEditorVisible", { dialog: "addArticle", status: false });
      });
    },
    isSelectedTagContains(tagName) {
      return _.has(this.selectedTags, tagName);
    },
    handleAddTag() {
      this.newTags.push({
        name: "",
        tags: [],
        inputVisible: false,
        newTagValue: "",
      });
    },
    handleDeleteTagObject(index) {
      this.$confirm("Are you sure to delete this category?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.newTags.splice(index, 1);
        })
        .catch(() => {});
    },
    handleCloseTag(tagObject, index) {
      tagObject.tags.splice(index, 1);
    },
    showInput(index) {
      this.newTags[index].inputVisible = true;
    },
    handleInputConfirm(tagObject) {
      let newTagValue = tagObject.newTagValue;
      if (newTagValue) {
        tagObject.tags.push(newTagValue);
      }
      tagObject.inputVisible = false;
      tagObject.newTagValue = "";
    },
    handleTagChange(itemName, value) {
      if (value) {
        this.$set(this.selectedTags, itemName, []);
      } else {
        this.$delete(this.selectedTags, itemName);
      }
    },
    saveNewTagToSetting() {
      this.saving = true;
      this.modules.genai.knowledge_document.tags = [
        ...this.modules.genai.knowledge_document.tags,
        ...this.newTags,
      ];
      this.$store
        .dispatch("SAVE_MODULES", {
          modules: this.modules,
        })
        .then(
          () => {
            this.saving = false;
            this.$message({
              type: "success",
              message: "Successfully save new tag to setting",
            });
          },
          () => {
            this.saving = false;
            this.$message({
              type: "error",
              message: "Error saving config",
            });
          }
        );
    },
    async handleCreateArticle() {
      try {
        let selectedTags = _.cloneDeep(this.selectedTags);
        if (this.newTags.length > 0) {
          selectedTags = {
            ...selectedTags,
            ...this.newTags.reduce((acc, item) => {
              const formatTagName = item.name.toLowerCase().replace(/\s/g, "_");
              if (!formatTagName) {
                this.isError = true;
              }
              acc[formatTagName] = item.tags;
              return acc;
            }, {}),
          };
          if (this.isError) {
            this.$notify({
              title: "Error",
              message: "Please input category name",
              type: "error",
            });
            this.isUploading = false;
            this.isError = false;
            return;
          }
          this.saveNewTagToSetting();
        }
        const newArticle = {
          id: uuid(),
          title: this.title,
          tags: JSON.stringify(selectedTags),
          content: "",
        };
        this.$emit("handleCreateArticle", newArticle);
      } catch (error) {
        this.$notify({
          title: "Error",
          message: "There was an error uploading the file",
          type: "error",
        });
      }
    },
  },
  computed: {
    genai_settings() {
      return this.modules.genai;
    },
    document_tags() {
      return this.genai_settings?.knowledge_document.tags || [];
    },
    modules: {
      get() {
        return this.$store.state.modules;
      },
      set(value) {
        this.$store.state.modules = value;
      },
    },
  },
};
</script>

<style></style>
