<template>
  <el-dialog
    title="Attributes"
    :visible.sync="dialogVisible"
    width="45%"
    :close-on-click-modal="false"
  >
    <div style="margin-bottom: 10px">
      <el-button
        class="btn-plus"
        size="mini"
        plain
        type="primary"
        style="margin: 0px; padding-left: 10px"
        icon="el-icon-plus"
        @click="addNewAttribute()"
        v-show="lengthAttributes() < 20"
      >
        Add field
      </el-button>
    </div>
    <div class="outreach-attribute">
      <div
        v-for="(value, key, i) in attributes"
        :key="i"
        class="attribute-item"
        :class="value.type === ATTRIBUTE_TYPE.DROPDOWN && 'dropdown'"
      >
        <div>
          <el-input
            placeholder="attribute_name"
            size="small"
            v-model="value.name"
            :disabled="value.isDeleted"
            maxlength="30"
            show-word-limit
          />
          <p
            style="margin: 0; padding: 2px 10px; color: red"
            v-show="renderMessageValidate(value.name)"
          >
            {{ renderMessageValidate(value.name) }}
          </p>
        </div>
        <el-select
          v-model="value.type"
          placeholder="attribute_type"
          size="small"
          class="attribute-dropdown-type"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          placeholder="Value 1, Value 2,..."
          size="small"
          v-model="value.value"
          v-show="value.type === ATTRIBUTE_TYPE.DROPDOWN"
          maxlength="150"
          show-word-limit
        />
        <el-input
          placeholder="Default value"
          size="small"
          v-model="value.defaultValue"
          v-if="!value.id && value.type === ATTRIBUTE_TYPE.TEXT_BOX"
          maxlength="150"
          show-word-limit
        />

        <el-input
          placeholder="Default value"
          type="textarea"
          :rows="2"
          v-model="value.defaultValue"
          v-if="!value.id && value.type === ATTRIBUTE_TYPE.TEXT_AREA"
          maxlength="5000"
          show-word-limit
        >
        </el-input>

        <el-select
          v-model="value.defaultValue"
          placeholder="Default value"
          size="small"
          v-else-if="!value.id && value.type === ATTRIBUTE_TYPE.CHECKBOX"
        >
          <el-option
            v-for="item in optionCheckbox"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="value.defaultValue"
          placeholder="Default value"
          size="small"
          v-else-if="!value.id && value.type === ATTRIBUTE_TYPE.DROPDOWN"
          class="attribute-dropdown-type"
        >
          <el-option
            v-for="item in getOptionFromDropDownValue(value.value)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <div class="action-attributes">
          <el-button
            class="btn-minus"
            size="mini"
            style="margin: 0px; padding-left: 10px; color: red; font-size: 14px"
            icon="el-icon-delete"
            v-show="!value.isDeleted && lengthAttributes() > 1"
            @click="removeAttribute(key)"
          >
          </el-button>
          <el-button
            class="btn-minus"
            size="mini"
            style="margin: 0px; padding-left: 10px; color: #67c23a; font-size: 14px"
            icon="el-icon-refresh-left"
            v-show="value.isDeleted"
            @click="revertAttribute(key)"
          >
          </el-button>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">Cancel</el-button>
      <el-button type="primary" @click="handleConfirmAttributes" :disabled="enableConfirmButton()"
        >Confirm</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
const CONTACT_ATTRIBUTES = require("@/graphql/OutReachContactAttributes.graphql");
import {
  attributeTypeOption,
  ATTRIBUTE_TYPE,
  convertNameToLabelAttribute,
  optionCheckbox,
  OPTION_CHECKBOX,
} from "@/helperMethods/outreach/attribute";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      attributes: {},
      options: attributeTypeOption(),
      valueSelect: "",
      ATTRIBUTE_TYPE,
      optionCheckbox: optionCheckbox(true),
      errors: {},
    };
  },
  methods: {
    renderMessageValidate(nameAttribute) {
      const name = nameAttribute.trim();
      if (!name) return "Field cannot be empty!";
      const countName = this.countAttributeName();
      if (countName[name]?.count && countName[name].count > 1) return "This name already exist!";
      return "";
    },
    getOptionFromDropDownValue(value) {
      const defaultOptions = [
        {
          label: OPTION_CHECKBOX.NONE,
          value: OPTION_CHECKBOX.NONE,
        },
      ];
      if (!value) return defaultOptions;
      const options = value.split(",");
      if (options.length)
        return [
          ...defaultOptions,
          ...options.map((value) => ({
            label: value,
            value,
          })),
        ];
    },
    enableConfirmButton() {
      const countObject = this.countAttributeName();
      const existDuplicate = Object.values(countObject).some((item) => item.count > 1);
      const existNoneNameAttribute = Object.values(this.attributes).some(
        (attribute) => !attribute.name
      );
      return existNoneNameAttribute || existDuplicate;
    },
    lengthAttributes() {
      return Object.keys(this.attributes).length;
    },
    addNewAttribute() {
      const lastKey = Object.keys(this.attributes)[Object.keys(this.attributes).length - 1];
      this.attributes = {
        ...this.attributes,
        [+lastKey + 1]: {
          name: "",
          value: "",
          type: ATTRIBUTE_TYPE.TEXT_BOX,
          defaultValue: "",
        },
      };
      if (this.lengthAttributes() === 20) {
        this.$message.error(
          `
          Reach limit attribute
          `
        );
      }
    },
    removeAttribute(key) {
      if (this.attributes[key]?.id) {
        this.attributes[key].isDeleted = true;
      } else {
        Vue.delete(this.attributes, key);
      }
    },
    revertAttribute(key) {
      this.attributes[key].isDeleted = false;
    },
    countAttributeName() {
      return Object.values(this.attributes).reduce((res, attr) => {
        const name = attr.name.trim();
        res[name] = res[name]
          ? {
              count: res[name].count + 1,
              name,
            }
          : {
              name,
              count: 1,
            };
        return res;
      }, {});
    },
    handleConfirmAttributes() {
      const attributeArray = Object.values(this.attributes).map((attribute) => {
        const name = attribute.name.trim().replace(/ /g, "_").toLowerCase();
        const { type, value, defaultValue = "", id = undefined } = attribute;
        let defaultValueForAttribute = defaultValue;
        if (
          (type === ATTRIBUTE_TYPE.CHECKBOX && defaultValue === OPTION_CHECKBOX.NONE) ||
          (type === ATTRIBUTE_TYPE.CHECKBOX && !defaultValue)
        ) {
          defaultValueForAttribute = OPTION_CHECKBOX.FALSE;
        }
        if (
          (type === ATTRIBUTE_TYPE.DROPDOWN && defaultValue === OPTION_CHECKBOX.NONE) ||
          (type === ATTRIBUTE_TYPE.DROPDOWN && !defaultValue)
        ) {
          defaultValueForAttribute = "";
        }
        const formattedName = JSON.stringify({
          label: convertNameToLabelAttribute(name.trim()),
          type,
          value:
            type === ATTRIBUTE_TYPE.DROPDOWN ? value.split(",").map((item) => item.trim()) : value,
        });
        return {
          ...attribute,
          name,
          label: formattedName,
          value: defaultValueForAttribute,
          id,
        };
      });
      this.$store.commit("SET_OUTREACH_LOADING", true);
      const self = this;
      this.$apollo
        .mutate({
          mutation: CONTACT_ATTRIBUTES,
          variables: {
            attributes: attributeArray,
          },
          update(store, { data: { outReachContactAPI } }) {
            self.$notify.success({
              title: "Success",
              position: "bottom-right",
              message: `Successfully add attributes.`,
            });
            self.$emit("refetch-data", true);
          },
        })
        .catch((err) => {
          self.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Failed to add attributes!`,
          });
          self.$store.commit("SET_OUTREACH_LOADING", false);
        });
    },
  },
  computed: {
    ...mapGetters(["contactAttributes"]),
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(data) {
        this.$emit("update:visible", data);
      },
    },
  },
  watch: {
    visible: {
      handler() {
        this.attributes = this.contactAttributes.length
          ? this.contactAttributes.reduce(
              (res, { name, isDeleted, id, type, value = [] }, index) => {
                return {
                  ...res,
                  [index + 1]: {
                    name,
                    isDeleted,
                    id,
                    type,
                    value: Array.isArray(value) ? value.join(", ") : value,
                  },
                };
              },
              {}
            )
          : {
              1: {
                name: "",
                value: "",
                defaultValue: "",
                type: ATTRIBUTE_TYPE.TEXT_BOX,
              },
            };
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.outreach-attribute {
  max-height: 40vh;
  overflow-y: scroll;
  .attribute-item {
    display: flex;
    margin: 15px 0;
    .attribute-dropdown-type {
      margin: 0 5px;
    }
    &:not(:first-child) {
      margin-top: 10px;
    }
    > div:not(:last-child) {
      min-width: 30%;
      width: 30%;
    }
    // > div:first-child {
    //   width: 43%;
    // }
    // > div:nth-child(2) {
    //   width: 43%;
    //   margin-left: 10px;
    // }
    .action-attributes {
      button {
        border: none;
        background: none !important;
        &:hover {
          background: none !important;
        }
      }
      .btn-delete {
        &:hover {
          path {
            stroke: #1d57d8;
          }
        }
      }
    }
    &.deleted {
      background: blue;
    }
  }
}
</style>
