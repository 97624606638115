<template>
  <div>
    <div id="plan-header">
      <h1 class="main-title">Plans</h1>
    </div>
    <div id="plan-body">
      <el-table>
        <el-table-column prop="name" label="Ask"></el-table-column>
        <el-table-column prop="description" label="Created on"></el-table-column>
        <el-table-column prop="created_at" label="Token Count"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
