<template>
  <advanced-json-editor
    :json.sync="editableJson"
    :height="640"
    @error="onError"
    @updated="onUpdated"
  ></advanced-json-editor>
</template>

<script>
import { mapGetters } from "vuex";
import AdvancedJsonEditor from "./AdvancedJsonEditor";
import _ from "lodash";

export default {
  name: "FormDetailOptions",
  components: {
    AdvancedJsonEditor,
  },
  props: {
    form: Object,
  },
  computed: {
    ...mapGetters(["showAdvanced"]),
    isCatIrtMode() {
      return _.get(this.form, "settings.options.catirt", false);
    },
    editableJson() {
      if (this.isCatIrtMode && !this.showAdvanced)
        return _.pick(this.form.settings.options, ["MAX_SUBMISSION"]);
      return this.form.settings.options;
    },
  },
  methods: {
    onError() {
      //TODO: show error
    },
    onUpdated(val) {
      this.form.settings.options = _.cloneDeep({ ...this.form.settings.options, ...val });
    },
  },
};
</script>

<style scoped></style>
