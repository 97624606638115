<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
      <template slot="title">
        <div class="plugin-modal-header">
          <div class="plugin-info">
            <div class="plugin-img">
              <img :src="SearchIcon" alt="" />
            </div>
            <div class="plugin-name">
              <p>Search Engine</p>
            </div>
          </div>
        </div>
      </template>
      <div class="plugin-modal-body">
        <div>
          <p>You are about to enable Search Engine.</p>
        </div>
        <div>
          <div>
            <p>Select Search Engine</p>
            <el-select v-model="tempConfig.name" placeholder="Select Search Engine">
              <el-option label="Google" value="google"></el-option>
              <el-option label="Bing" value="bing"></el-option>
            </el-select>
          </div>
          <div>
            <!-- Whitelist websites -->
            <div style="display: flex; align-items: center">
              <p>Whitelist websites</p>
              <!-- tooltip -->
              <el-tooltip
                effect="dark"
                content="Websites that you want the search engine to be searched on, leave it empty to search on all websites"
                placement="top"
                style="margin-left: 5px"
              >
                <i class="el-icon-info"></i>
              </el-tooltip>
            </div>
            <div
              style="
                padding: 10px;
                border: 1px solid #dedede;
                gap: 10px;
                box-sizing: border-box;
                border-radius: 5px;
              "
            >
              <div v-if="whiteList.length > 0">
                <div v-for="(website, index) in whiteList" :key="index" style="margin-bottom: 10px">
                  <div style="margin-right: 10px; width: 100%; display: flex; gap: 10px">
                    <el-input
                      v-model="website.url"
                      size="mini"
                      style="width: 100%"
                      @input="validateWebsiteUrl(website, index)"
                      :class="{ 'is-invalid': !website.isValid && website.url }"
                    ></el-input>
                    <el-button
                      size="mini"
                      type="danger"
                      icon="el-icon-delete"
                      @click="handleRemoveWebsite(index)"
                    ></el-button>
                  </div>

                  <p
                    v-if="!website.isValid && website.url"
                    style="color: red; font-size: 12px; margin: 0"
                  >
                    {{ website.error }}
                  </p>
                </div>
              </div>

              <div>
                <el-button size="mini" type="primary" @click="handleAddWebsite"
                  >Add website</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <template slot="footer">
        <div>
          <el-button type="plain" @click="handleClose">Cancel</el-button>
          <el-button type="primary" @click="handleConfirm">Confirm</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import SearchIcon from "./assets/icons/search-engine.png";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    selectedPlugin: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      test: false,
      tempConfig: {
        name: "",
        whiteList: [],
      },
      SearchIcon,
      inputVisible: false,
      inputValue: "",
      whiteList: [],
    };
  },
  methods: {
    handleClose() {
      this.$emit("updateDialogVisible", false);
    },
    handleConfirm() {
      this.$confirm("Do you want to save the current settings ?", "Warning", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          const whiteList = this.whiteList.filter((item) => {
            return item.isValid && item.url;
          });
          this.tempConfig.whiteList = whiteList.map((item) => item.url);
          this.$emit("updatePluginConfig", this.tempConfig);
        })
        .catch(() => {});
    },

    validateWebsiteUrl(website, index) {
      // Check if duplicate website
      const isDuplicate = this.whiteList.some((item, i) => item.url === website.url && i !== index);
      if (isDuplicate) {
        this.whiteList[index].isValid = false;
        this.whiteList[index].error = "Duplicate URL";
        return;
      }

      const urlRegex = new RegExp(/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/);
      const isValid = urlRegex.test(website.url); // Validate URL
      if (!isValid) {
        this.whiteList[index].isValid = false;
        this.whiteList[index].error = "Invalid URL";
      } else {
        this.whiteList[index].isValid = true;
        this.whiteList[index].error = "";
      }
    },

    handleAddWebsite() {
      this.whiteList.push({ url: "", isValid: true, error: "" });
    },
    handleRemoveWebsite(index) {
      this.whiteList.splice(index, 1);
    },
  },
  created() {
    if (this.selectedPlugin) {
      this.tempConfig = _.cloneDeep(this.selectedPlugin.config);
      this.whiteList = this.selectedPlugin.config.whiteList.map((item) => {
        return { url: item, isValid: true, error: "" };
      });
    }
  },
};
</script>

<style lang="scss">
@import "./assets/css/modal.scss";

.is-invalid input {
  border-color: red;
}

.is-invalid input:focus {
  border-color: red;
}
</style>
