import Vue from "vue";
import { MutationTree } from "vuex/types";

import { CustomerCareState } from "./types";

const mutations: MutationTree<CustomerCareState> = {
  CUSTOMER_CARE_SET_DASHBOARDS: (state, payload) => {
    Vue.set(state, "dashboards", payload);
  },
  CUSTOMER_CARE_SET_SELECTED_TAB: (state, payload) => {
    Vue.set(state, "selectedTab", payload);
  },
  CUSTOMER_CARE_SET_EDITOR_VISIBLE: (state, payload) => {
    Vue.set(state, "editorVisible", payload);
  },
  CUSTOMER_CARE_TOGGLE_EDITOR_VISIBLE: (state) => {
    Vue.set(state, "editorVisible", !state.editorVisible);
  },
};

export default mutations;
