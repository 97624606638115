<template>
  <div id="kb-status-modal">
    <el-dialog
      title="Document Status"
      :visible.sync="statusDialogVisible"
      width="90%"
      :before-close="handleClose"
    >
      <div style="font-size: 16px">
        <div class="status">
          <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
            <div>
              <span>Status: </span>
              <span v-if="selectedDocumentStatus.completed" class="completed">Completed</span>
              <div style="display: inline" v-else>
                <span class="not-completed">Not Complete </span>
                <div style="display: inline">
                  <el-button
                    class="refresh-button"
                    :class="{ active: isRefreshingStatus }"
                    @click="refreshStatus"
                    size="mini"
                    circle
                    icon="el-icon-refresh"
                  >
                  </el-button>
                </div>
              </div>
            </div>
            <div>
              <div v-if="selectedDocument?.updatedAt">
                Uploaded on: {{ formattedDate(selectedDocument.updatedAt) }}
              </div>
              <div v-if="selectedDocument?.uploadedBy">
                Uploaded by: {{ selectedDocument.uploadedBy }}
              </div>
            </div>
          </div>
        </div>
        <div class="step">
          <div style="margin-bottom: 20px">Progress:</div>
          <el-steps :active="activeStep" finish-status="success">
            <el-step v-for="(step, index) in steps" :key="index" :title="step"></el-step>
          </el-steps>
        </div>
        <div v-if="this.isWebsite" style="margin-top: 30px; font-size: 16px">
          Note: Upload is needed once the scraping is completed to continue the process.
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    statusDialogVisible: {
      type: Boolean,
      default: false,
    },
    selectedDocumentStatus: {
      type: Object,
      default: null,
    },
    isRefreshingStatus: {
      type: Boolean,
      default: false,
    },
    isWebsite: {
      type: Boolean,
      default: false,
    },
    selectedDocument: {
      type: Object,
      default: null,
    },
  },
  computed: {
    activeStep() {
      const completedStep = this.selectedDocumentStatus.completed_steps;
      if (this.isWebsite) {
        return completedStep?.length + 2;
      }
      return completedStep?.length + 1;
    },
    steps() {
      if (this.isWebsite) {
        return [
          "Scraping Started",
          "Scraping Completed",
          "Extract",
          "Partition",
          "Gen Embeddings",
          "Save Embeddings",
          "Summarize",
          "Gen Embeddings",
          "Save Embedding",
        ];
      }
      return [
        "Upload Document",
        "Extract",
        "Partition",
        "Gen Embeddings",
        "Save Embeddings",
        "Summarize",
        "Gen Embeddings",
        "Save Embedding",
      ];
    },
  },
  methods: {
    handleClose() {
      this.$emit("updateStatusDialogVisible", false);
    },
    refreshStatus() {
      this.$emit("refreshDocumentStatus", this.selectedDocumentStatus.document_id);
    },
    formattedDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm A");
    },
  },
};
</script>

<style lang="scss">
#kb-status-modal {
  .completed {
    color: green;
  }

  .not-completed {
    color: red;
  }

  .step {
    margin-top: 10px;
  }

  .status {
    margin: 10px 0;

    .refresh-button {
      margin-left: 10px;
      &.active {
        animation: spin 2s linear infinite;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
</style>
