<template>
  <div id="form_score_settings">
    <div class="score_settings_header">
      <span>Question Bank</span>
    </div>
    <div class="score_settings_container">
      <!-- INSTRUCTION -->
      <p class="instructions">
        Copy paste your questions here. Our AI will try to convert the questionnaire into the JSON
        format needed by our system. Please double check the conversion value and help to revise in
        case AI does not correct.
      </p>

      <!-- INPUT TEXT -->
      <div class="textarea-container">
        <el-input
          class="big-textarea"
          type="textarea"
          :rows="20"
          placeholder="Enter your list of questions here"
          style="margin-top: 20px"
        ></el-input>
      </div>
      <!-- BUTTONS -->
      <el-row style="margin-top: 20px">
        <el-col :span="12"
          ><el-button type="primary" icon="el-icon-magic-stick" round size="medium"
            >Convert</el-button
          ><el-button type="warning" icon="el-icon-plus" round size="medium"
            >Generate sample</el-button
          >
        </el-col>
        <el-col :span="12"> <p style="float: right">50.000 chars / 20k tokens</p></el-col>
      </el-row>

      <!-- RESULTS -->
      <el-divider />
      <h3>Results:</h3>
      <div class="textarea-container">
        <el-input
          class="big-textarea"
          type="textarea"
          :rows="20"
          style="margin-top: 20px"
        ></el-input>
      </div>

      <el-row style="margin-top: 20px">
        <el-col :span="12"
          ><el-button type="primary" icon="el-icon-dusj" round size="medium">Save</el-button
          ><el-button type="warning" icon="el-icon-plus" round size="medium">Evaluate</el-button>
        </el-col>
        <el-col :span="12"> <p style="float: right">Valid JSON</p></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "FormDetailNaturalQuestions",
  data() {
    return {
      scoreForm: {
        scores: [],
        result: null,
      },
      mathForm: {
        scores: [],
        result: null,
      },
    };
  },
  props: {
    form: Object,
  },
  methods: {
    addScore() {
      this.scoreForm.scores.push("");
    },
    removeScore(index) {
      this.scoreForm.scores.splice(index, 1);
    },
    addFinalScore() {
      this.mathForm.scores.push("");
    },
    removeFinalScore() {
      this.mathForm.scores.push("");
    },
    evaluateExpression() {
      try {
        this.mathForm.result = eval(this.mathForm.expression);
      } catch (error) {
        this.mathForm.result = null;
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/colors.scss";

$main-color: $color-primary;

@mixin header-style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $main-color;
  color: #fff;
  min-height: 35px;
  font-size: 0.9em;
  font-weight: bold;
  padding: 5px 10px;
}

#form_score_settings {
  border: 2px solid $main-color;
  border-radius: 10px 20px;
  overflow: hidden;

  .score_settings_header {
    @include header-style();
  }
}

.score_settings_container {
  padding: 20px;
}
.instructions {
  margin: 10px 10px 0 0;
}

.textarea-container {
  width: 100%;
}

.big-textarea {
  width: 100%;
  resize: vertical;
  margin-top: 5px;
}
</style>
