<template>
  <div>
    <!-- INFORMATION -->
    <el-collapse v-model="activeNames" @change="handleChange" style="margin-bottom: 20px">
      <el-collapse-item title="Instruction" name="1">
        <p>
          Please provide step-by-step calculations on the JSON values before. All calculations under
          "after-question" are processed each time a question is answered by user. All calculations
          under "after-submit" are processed after user answered the last questions. Result under
          "after-submit" will be stored on form result table.

          <br /><br />
          Notes:<br />
          <span v-pre>
            - Under "after-question", variable "iif_next_question_idx" is necessary for system to
            select the next question based on this number. For example, the "iif_next_question_idx"
            calculated as 5, then next question will be the question no 5 your question banks.
            <br />
            - All formula has to be under double bracket as seen below</span
          >
        </p>
      </el-collapse-item>

      <el-collapse-item title="Available variables" name="2">
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column prop="variable" label="variable" width="auto" />
          <el-table-column prop="description" label="description" width="auto" />
        </el-table>
      </el-collapse-item>
    </el-collapse>

    <!-- JSON -->
    <advanced-json-editor
      :json.sync="this.form.settings.formulas"
      :height="640"
      @error="onError"
      @updated="onUpdated"
    ></advanced-json-editor>
  </div>
</template>

<script>
import AdvancedJsonEditor from "./AdvancedJsonEditor";
import _ from "lodash";

export default {
  name: "FormDetailScoring",
  components: {
    AdvancedJsonEditor,
  },
  props: {
    form: Object,
  },
  methods: {
    onError() {
      //TODO: show error
    },
    onUpdated(val) {
      this.form.settings.formulas = _.cloneDeep(val);
    },
  },
  created() {
    if (!_.has(this.form.settings, "formulas")) {
      this.$set(this.form.settings, "formulas", {
        "after-question": {
          iif_next_question_idx: "{{ Number(iif_next_question_idx)+1 }}",
        },
        "after-submit": {},
      });
    }
  },
  computed: {
    tableData() {
      return [
        {
          variable: `form.${this.form.id}.resultSet.[fieldId].value`,
          description: `the answer user filled in. for example, if you have a field with ID 'age', use {{ form.${this.form.id}.resultSet.age.value }}`,
        },
        {
          variable: "iif_next_question_idx",
          description: "the next question index. it starts from 0.",
        },
      ];
    },
  },
  created() {
    if (!_.has(this.form.settings, "formulas")) {
      this.$set(this.form.settings, "formulas", {
        "after-question": {
          iif_next_question_idx: "{{ Number(iif_next_question_idx)+1 }}",
          iif_question_histories:
            "{{ [...JSON.parse(iif_question_histories), Number(iif_next_question_idx)] }}",
        },
        "after-submit": {},
      });
    }
  },
};
</script>

<style scoped></style>
