import _ from "lodash";

import { rest } from "@/store/api";
import { RootState } from "@/store/types";
import { ActionTree } from "vuex/types";
import { EcareState } from "./types";

const actions: ActionTree<EcareState, RootState> = {
  ECARE_GET_DASHBOARDS: async ({ state, commit, dispatch }) => {
    const result = await rest("get", "get_metabase_dashboards");
    commit("ECARE_SET_DASHBOARDS", _.sortBy(result, ["id"]));
    return result;
  },
  ECARE_SET_SELECTED_TAB: async ({ state, commit, dispatch }, payload) => {
    commit("ECARE_SET_SELECTED_TAB", payload);
  },
  ECARE_SET_EDITOR_VISIBLE: async ({ state, commit, dispatch }, payload) => {
    commit("ECARE_SET_EDITOR_VISIBLE", payload);
  },
  ECARE_TOGGLE_EDITOR_VISIBLE: async ({ state, commit, dispatch }) => {
    commit("ECARE_TOGGLE_EDITOR_VISIBLE");
  },
};

export default actions;
