<template>
  <section class="outreach-tag-segments">
    <div class="tag-component-list-tag">
      <table class="tag-component-list-tag-table" aria-hidden="true">
        <thead>
          <tr class="tag-component-list-tag-table-head">
            <th class="tag-component-list-tag-table-head-title">
              <p>Metrics</p>
            </th>
            <th
              class="tag-component-list-tag-table-head-action"
              v-if="checkRoles(getPermittedRoles(moduleName))"
            >
              <el-button size="mini" icon="el-icon-plus" type="text" @click="showAddForm">
                Add metric
              </el-button>
            </th>
          </tr>
        </thead>
        <tbody v-if="metrics.length > 0">
          <tr
            v-for="metric in metrics"
            :key="metric.id"
            class="tag-component-list-tag-table-row"
          >
            <td class="tag-component-list-tag-table-row-title">
              <p>{{ metric.name }}</p>
            </td>
            <td
              v-if="checkRoles(getPermittedRoles(moduleName))"
              class="tag-component-list-tag-table-row-action action-button show"
              :class="metric.id && 'show'"
            >
              <div>
                <el-button
                  class="list-tag-button-edit"
                  icon="el-icon-edit-outline"
                  size="mini"
                  @click="showEditForm(metric)"
                >
                </el-button>
                <el-button
                  size="mini"
                  @click="confirmDelete(metric)"
                  style="margin: 0px; padding-left: 10px"
                  icon="el-icon-delete"
                  type="danger"
                  class="list-tag-button-delete"
                >
                </el-button>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="tag-component-list-tag-table-row-title" style="padding-bottom: 0">
            No metrics found`;
          </tr>
        </tbody>
      </table>
      <metric-form
        v-if="metric"
        :visible.sync="formVisible"
        :metric="metric"
        @cancel="handleCancel"
        @submit="handleSubmit"
      />
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import { checkRoles, getPermittedRoles } from "@/helperMethods/auth";
import { OUT_REACH_TAG_TYPE } from "@/store/modules/outreach/types";
import { OUT_REACH_MODULE } from "@/helperMethods/outreach";
import MetricForm from "./MetricForm.vue";
import _ from "lodash";

import TAG_DESTROY from "@/graphql/OutReachTagDestroy.graphql";
import TAG_CREATE from "@/graphql/OutReachTagCreate.graphql";
import TAG_UPDATE from "@/graphql/OutReachTagUpdate.graphql";
import DeleteIcon from "@/components/SvgIcon/DeleteIcon.vue";
import EditIcon from "@/components/SvgIcon/EditIcon.vue";

export default Vue.extend({
  components: { MetricForm, DeleteIcon, EditIcon },
  data() {
    return {
      formVisible: false,
      metric: null,
      moduleName: OUT_REACH_MODULE,
    };
  },
  computed: {
    metrics() {
      return Object.values(this.$store.state.outreach.metrics);
    },
    label() {
      return 'Metric';
    },
  },
  methods: {
    checkRoles,
    getPermittedRoles,
    showAddForm() {
      this.metric = {};
      this.formVisible = true;
    },
    showEditForm(metric) {
      this.metric = { ...metric };
      this.formVisible = true;
    },
    confirmDelete(metric) {
      this.$confirm(`This will permanently delete ${metric.name}.`, "Warning", {
        confirmButtonClass: "btn-kr-style",
        cancelButtonClass: "btn-cancel",
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("DELETE_OUTREACH_METRIC", metric.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `Delete ${metric.name} canceled`,
          });
        });
    },
    handleCancel() {
      this.formVisible = false;
      this.metric = null;
    },
    handleSubmit(metric) {
      this.formVisible = false;
      this.metric = null;
      if (metric.id == null) {
        this.$store.dispatch("ADD_OUTREACH_METRIC", metric);
      } else {
        this.$store.dispatch("EDIT_OUTREACH_METRIC", metric);
      }
    },
  },
});
</script>

<style lang="scss">
$primary-KR-color: #1d57d8;
$row-bg-hover: #f4f7fa;
$base-date-picker-color: #1d57d8;
$button-bg-hover: #e8eefb;
$button-border-hover: #bbcdf3;
@mixin text-style {
  font-family: "Avenir";
  font-style: normal;
  line-height: 16px;
  margin: 0;
}
.btn-cancel {
  color: $primary-KR-color !important;
  border-color: $primary-KR-color !important;
  &:hover {
    border-color: $primary-KR-color !important;
  }
}

.btn-kr-style {
  background: $primary-KR-color !important;
  border-color: $primary-KR-color !important;
  color: white !important;
  &:hover {
    background: #4573d7 !important;
    border-color: $primary-KR-color !important;
    color: white !important;
  }
}
.tag-component-list-tag {
  p,
  button {
    @include text-style;
  }
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  &-table {
    width: 100%;
    padding-bottom: 17px;
    border-spacing: 0;
    &-head {
      border-bottom: 1px solid #cecece;
      padding: 5px;
      box-sizing: border-box;
      font-size: 16px;

      &-title {
        padding: 10px 17px;
        line-height: 16px;
        font-weight: 500;
        color: #000000;
      }
      &-action {
        button {
          font-weight: 500;
          font-size: 14px;
          color: $primary-KR-color;
        }
      }
    }
    &-row {
      border-bottom: 1px solid #ebeef5;
      &:hover {
        background: $row-bg-hover;
        .action-button {
          display: block;
        }
      }
      &-action {
        .list-tag-button-delete {
          color: #f56c6c;
          i {
            font-size: 16px;
          }
          &:hover {
            color: #f78989;
          }
        }
        .list-tag-button-edit {
          color: #409eff;
          i {
            font-size: 16px;
          }
          &:hover {
            color: #66b1ff;
          }
        }
        button {
          border: none;
          background: none !important;
          &:hover {
            // background: none !important;
          }
          &:first-child {
            &:hover {
              path {
                fill: #1d57d8;
              }
            }
          }
          &:last-child {
            &:hover {
              path {
                stroke: #1d57d8;
              }
            }
          }
        }
        display: none;
        &.show {
          display: block;
        }
      }
      &-title {
        padding: 10px 19px;
        font-size: 14px;
        color: #000000;
      }
    }
    tr {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    td:last-child,
    th:last-child {
      width: 100px;
      text-align: start;
    }
  }
}
</style>
