<template>
  <div class="dashboard">
    <!-- <div class="top">
      <div>
        <el-select v-if="!editMode" v-model="selectedDashboard" placeholder="Select" @change="selectDashboard">
          <el-option
            v-for="dashboard in dashboards"
            :key="dashboard.id"
            :label="dashboard.name"
            :value="dashboard.public_uuid">
          </el-option>
        </el-select>
      </div>
      <el-button class="toggle-button" type="primary" :icon="buttonIcon" @click="openEditor">{{ buttonLabel }}</el-button>
    </div> -->
    <div class="middle">
      <iframe
        v-if="!editMode"
        :src="url"
        frameborder="0"
        width="100%"
        height="100%"
        allowtransparency
      ></iframe>
      <iframe
        v-if="editMode"
        :src="settings.metabase.url"
        frameborder="0"
        width="100%"
        height="100%"
        allowtransparency
      ></iframe>
    </div>
    <div class="footer">
      <el-tabs v-model="selectedDashboard" tab-position="bottom" type="card">
        <el-tab-pane
          v-for="dashboard in dashboards"
          :key="dashboard.id"
          :label="dashboard.name"
          :name="dashboard.public_uuid"
        ></el-tab-pane>
      </el-tabs>
      <el-button
        class="toggle-button-footer"
        type="primary"
        :icon="buttonIcon"
        @click="openEditorInTab"
        >{{ buttonLabel }}</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editMode: false,
      selectedDashboard: "",
    };
  },
  computed: {
    url() {
      return `${this.settings.metabase.url}/public/dashboard/${this.selectedDashboard}`;
    },
    buttonIcon() {
      return this.editMode ? "el-icon-monitor" : "el-icon-edit";
    },
    buttonLabel() {
      return this.editMode ? "Display" : "Editor";
    },
    settings() {
      return this.$store.state.modules.analytics;
    },
    dashboards() {
      return this.$store.state.ecare.dashboards;
    },
  },
  watch: {
    "$store.state.ecare.dashboards": function (dashboards) {
      this.selectedDashboard = dashboards[0].public_uuid;
    },
  },
  methods: {
    selectDashboard(value) {
      this.selectedDashboard = value;
    },
    openEditor() {
      this.editMode = !this.editMode;
    },
    openEditorInTab() {
      this.$store.dispatch("ECARE_SET_EDITOR_VISIBLE", true);
      this.$store.dispatch("ECARE_SET_SELECTED_TAB", "chart-editor");
    },
  },
  mounted() {
    const firstDashboard = this.$store.state.ecare.dashboards[0];
    if (firstDashboard) {
      this.selectedDashboard = firstDashboard.public_uuid;
    }
  },
};
</script>

<style scoped>
.dashboard {
  height: 100%;
}

.top {
  display: flex;
  justify-content: space-between;
}

.middle {
  height: 100%;
  margin-top: 16px;
}

.toggle-button {
  position: fixed;
  top: 10px;
  right: 10px;
}

.toggle-button-footer {
  align-self: flex-end;
}

.footer {
  position: fixed;
  background-color: white;
  bottom: 0px;
  right: 0px;
  left: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
</style>
