<template>
  <el-tabs v-model="selectedTab" @tab-click="handleTabClick" id="ecare">
    <el-tab-pane label="Dashboard" name="dashboard">
      <Dashboard />
    </el-tab-pane>
    <el-tab-pane v-if="editorVisible" label="Chart Editor" name="chart-editor">
      <ChartEditor />
    </el-tab-pane>
    <el-tab-pane label="Patient Journey" name="patient-journey">
      <PatientJourney />
    </el-tab-pane>
    <el-tab-pane label="Patients" name="patients">
      <Patient />
    </el-tab-pane>
    <el-tab-pane label="CAT-IRT forms" name="form" class="full-grid" v-if="showAdvanced">
      <Form :category="'cat-irt'" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import Dashboard from "./Dashboard/Index.vue";
import ChartEditor from "./ChartEditor/Index.vue";
import PatientJourney from "./PatientJourney/Index.vue";
import Patient from "./Patient/Index.vue";
import Form from "@/components/Form/Index.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Dashboard,
    ChartEditor,
    PatientJourney,
    Patient,
    Form,
  },
  data() {
    return {
      activeName: "dashboard",
    };
  },
  mounted() {
    this.$store.dispatch("ECARE_GET_DASHBOARDS");
    this.$store.dispatch("FETCH_OUTREACH_SEGMENTS");
    this.$store.dispatch("FETCH_CONTACT_ATTRIBUTES");
    this.$store.dispatch("FETCH_OUTREACH_METRICS");
  },
  computed: {
    ...mapGetters(["showAdvanced"]),
    selectedTab: {
      get() {
        return this.$store.state.ecare.selectedTab;
      },
      set(value) {
        this.$store.dispatch("ECARE_SET_SELECTED_TAB", value);
      },
    },
    editorVisible() {
      return this.$store.state.ecare.editorVisible;
    },
  },
  methods: {
    handleTabClick(tab, event) {
      if (tab.name !== "chart-editor") {
        this.$store.dispatch("ECARE_SET_EDITOR_VISIBLE", false);
      }
    },
  },
};
</script>

<style>
#ecare.el-tabs {
  height: 100%;
}

#ecare .el-tabs__content {
  height: calc(100% - 50px);
}

#pane-dashboard {
  height: 100%;
}
</style>

<style lang="scss" scoped>
@import "../../assets/scss/colors.scss";

$border-color: $color-light;
$box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
.full-grid {
  display: grid;
  align-self: stretch;
  border: 1px solid $border-color;
  box-shadow: $box-shadow;
  border-radius: 5px;
}
</style>
