<template>
  <el-form label-position="left" label-width="200px" :model="form">
    <el-form-item label="ID">
      <el-input :value="form.id" readonly></el-input>
    </el-form-item>
    <el-form-item label="Name">
      <el-input v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item label="Description">
      <el-input v-model="form.description"></el-input>
    </el-form-item>

    <el-divider />
    <el-form-item label="Enable CAT-IRT mode" v-if="form.settings">
      <el-checkbox v-model="form.settings.options.catirt" v-on:change="onCatIrtCheckboxChanged" />
    </el-form-item>
  </el-form>
</template>

<script>
const defaultFormula = {
  "after-question": {
    iif_next_question_idx: "{{ Number(iif_next_question_idx)+1 }}",
  },
  "after-submit": {},
};
const defaultCatIrtFormula = {
  "after-question": {
    iif_selected:
      "{{ { option: { a: JSON.parse(iif_questions)[iif_next_question_idx].options[iif_selected_option_idx].a, b: JSON.parse(iif_questions)[iif_next_question_idx].options[iif_selected_option_idx].b }, question: { a: JSON.parse(iif_questions)[iif_next_question_idx].a, b: JSON.parse(iif_questions)[iif_next_question_idx].b } } }}",
    iif_current_theta:
      "{{ _.round(Number(iif_current_theta) + Number(JSON.parse(iif_selected).option.a) * (1 - 2 * Number(JSON.parse(iif_selected).option.b)), 3) }}",
    iif_theta_probability:
      "{{ 1 / (1 + Math.exp(-JSON.parse(iif_selected).question.a * (iif_current_theta))) }}",
    iif_next_question_idx:
      "{{ JSON.parse(iif_question_histories).length > 14 ? -1 : _.chain(JSON.parse(iif_questions)).map((question, index) => ({ question, index })).reject(({ question, index }) => JSON.parse(iif_question_histories).includes(index)).map(({ question, index }) => { const iif = question.a ** 2 * iif_theta_probability * (1 - iif_theta_probability); return { index, iif }; }).maxBy('iif').get('index', -1).value() }}",
    iif_current_values:
      "{{ _.chain(JSON.parse(iif_questions)).map((question, index) => ({ question, index })).reject(({ question, index }) => JSON.parse(iif_question_histories).includes(index)).map(({ question, index }) => { const iif = question.a ** 2 * iif_theta_probability * (1 - iif_theta_probability); return { index, iif }; }).value() }}",
    iif_question_histories:
      "{{ [...JSON.parse(iif_question_histories), Number(iif_next_question_idx)] }}",
  },
  "after-submit": {
    finalScore: "{{ iif_current_theta }}",
  },
};

export default {
  name: "FormDetailBasic",
  props: {
    form: Object,
  },
  methods: {
    onCatIrtCheckboxChanged(enabled) {
      const formula = enabled ? defaultCatIrtFormula : defaultFormula;
      this.$set(this.form.settings, "formulas", formula);
      this.$message.success(
        `Default scoring updated to ${enabled ? "CAT-IRT" : "non CAT-IRT"} form type`
      );
    },
  },
};
</script>

<style scoped></style>
