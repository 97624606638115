<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
      <template slot="title">
        <div class="plugin-modal-header">
          <div class="plugin-info">
            <div class="plugin-img">
              <img :src="selectedPlugin.icon" alt="" />
            </div>
            <div class="plugin-name">
              <p>{{ selectedPlugin.name }}</p>
              <span>{{ selectedPlugin.provider }}</span>
            </div>
          </div>
        </div>
      </template>
      <div class="plugin-modal-body">
        <div>
          <p>You are about to enable GitHub. To continue, you will authorize the following:</p>
          <span> - Read and Write access to pull requests</span>
        </div>
        <div>
          <p><b>Log in to GitHub to continue</b></p>
        </div>
        <div style="margin-bottom: 10px">
          <el-input
            placeholder="Enter your GitHub Personal Access Token"
            v-model="githubAccessToken"
          />
        </div>
        <div>
          <span>For more information on how to generate a PAT for Github, </span>
          <a
            style="color: blue; text-decoration: underline"
            href="https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/managing-your-personal-access-tokens"
            target="_blank"
          >
            click here</a
          >
        </div>
        <div>
          <p><b>Configuration</b></p>
          <p>Some additional information is required to enable GitHub's REST APIs.</p>
        </div>
        <div>
          <el-input
            placeholder='Enter the account owner of repository. i.e., "microsoft"'
            v-model="repositoryAccountOwner"
          />
        </div>
        <div style="margin-top: 10px">
          <el-input
            placeholder='Enter the name of repository. i.e., "keyrepy-chatbot"'
            v-model="repositoryName"
          />
        </div>
      </div>
      <template slot="footer">
        <div>
          <el-button type="plain" @click="handleClose">Cancel</el-button>
          <el-button type="primary" @click="handleClose">Enable</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    selectedPlugin: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      githubAccessToken: "",
      repositoryAccountOwner: "",
      repositoryName: "",
    };
  },
  methods: {
    handleClose() {
      this.$emit("updateDialogVisible", false);
    },
  },
};
</script>

<style lang="scss">
@import "./assets/css/modal.scss";
</style>
