import actions from "./actions";
import mutations from "./mutations";
import state from "./state";
import { Module } from "vuex/types";
import { CustomerCareState } from "./types";
import { RootState } from "@/store/types";

const customercare: Module<CustomerCareState, RootState> = {
  // namespaced: true,
  actions,
  mutations,
  state,
};

export default customercare;
