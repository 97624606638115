<template>
  <el-dropdown @command="doCommand">
    <el-button size="mini" icon="el-icon-more" circle></el-button>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="reply">Reply</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: "MessageAction",
  props: {
    message: Object,
  },
  methods: {
    doCommand(event) {
      switch (event) {
        case "reply":
          this.$store.commit("SET_REPLY", this.message);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style></style>
