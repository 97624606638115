<template>
  <div class="filter-contacts">
    <div class="filter-contacts-container">
      <div class="filter-contacts-container-filters">
        <div class="filter-contacts-container-filters-container">
          <div
            class="filter-contacts-container-filters-container-item"
            v-for="(value, key, i) in filterList"
            :key="i"
          >
            <el-select
              size="small"
              v-model="value.variable"
              placeholder="Variable"
              style="width: 500px"
            >
              <el-option
                v-for="(item, j) in variables"
                :key="j"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              size="small"
              v-model="value.comparison"
              placeholder="Comparison"
              style="width: 500px"
            >
              <el-option
                v-for="(item, j) in getComparisonOptionsByKey(key)"
                :key="j"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- channel select value -->
            <el-select
              size="small"
              v-model="value.value"
              placeholder="Variable"
              v-if="value.variable === 'channel'"
            >
              <el-option
                v-for="(item, j) in getOptionByVariable(value.variable)"
                :key="j"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- tag select value -->
            <el-select
              size="small"
              v-model="value.valueArray"
              multiple
              collapse-tags
              placeholder="Variable"
              v-else-if="value.variable === 'tags'"
            >
              <el-option
                v-for="(item, j) in getOptionByVariable(value.variable)"
                :key="j"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- stage select value -->
            <el-select
              size="small"
              v-model="value.value"
              placeholder="Variable"
              v-else-if="value.variable === 'stage'"
            >
              <el-option
                v-for="(item, j) in getOptionByVariable(value.variable)"
                :key="j"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-input
              placeholder="Value"
              size="small"
              v-model="value.value"
              v-else-if="!getAttributeByName(value.variable).type"
            ></el-input>
            <div v-else-if="getAttributeByName(value.variable).type">
              <el-input
                placeholder="Value"
                size="small"
                v-model="value.value"
                v-if="getAttributeByName(value.variable).type === ATTRIBUTE_TYPE.TEXT_BOX"
              />
              <el-input
                placeholder="Value"
                size="small"
                v-model="value.value"
                v-else-if="getAttributeByName(value.variable).type === ATTRIBUTE_TYPE.TEXT_AREA"
              />
              <el-select
                size="small"
                style="width: 100%"
                v-model="value.value"
                placeholder="Variable"
                v-else-if="
                  getAttributeByName(value.variable).type === ATTRIBUTE_TYPE.DROPDOWN ||
                  getAttributeByName(value.variable).type === ATTRIBUTE_TYPE.CHECKBOX
                "
              >
                <el-option
                  v-for="(item, j) in getAttributeByName(value.variable).options"
                  :key="j"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div
          style="width: 20px; margin-right: 5px"
          class="filter-contacts-container-filters-btn-add"
        >
          <img alt="" :src="AddIcon" @click="addNewComparison" v-show="!filterList[5]" />
        </div>
      </div>
      <div class="filter-contacts-container-button">
        <el-button
          v-for="(btn, i) in buttons"
          :key="i"
          @click="btn.action"
          size="small"
          class="filter-contacts-container-button"
          :class="btn.type"
          >{{ btn.text }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getOutReachVariableExcludeField,
  getDefaultFilterOutReach,
  OUT_REACH_FILTER_COMPARISON,
  optionChannelFilter,
  haveContainComparisonKey,
  getComparisonOptions,
} from "@/helperMethods/outreach";
import {
  ATTRIBUTE_TYPE,
  optionDropdownAttribute,
  optionCheckbox,
} from "@/helperMethods/outreach/attribute";
import AddIcon from "../assets/add_icon.svg";
export default {
  props: ["setCloseFilter"],
  data() {
    return {
      clicked: 0,
      filterList: {
        1: {
          variable: undefined,
          comparison: OUT_REACH_FILTER_COMPARISON.IS,
          value: "",
          valueArray: [],
        },
      },
      buttons: [
        {
          text: "Reset",
          action: () => this.onResetFilterList(true),
          type: "reset",
        },
        {
          text: "Apply Filter",
          action: () => this.onApplyFilter(),
          type: "apply",
        },
      ],
      // icon
      AddIcon,
      // import
      getComparisonOptions,
      // attribute helper
      ATTRIBUTE_TYPE,
    };
  },
  computed: {
    ...mapGetters([
      "contactsArray",
      "contactsTags",
      "contactsStages",
      "contactFilterBoard",
      "contactAttributes",
    ]),
    variables() {
      return getOutReachVariableExcludeField(undefined, this.contactAttributes);
    },
    contactAttributeMap() {
      return this.contactAttributes.reduce((res, item) => ({ ...res, [item.name]: item }), {});
    },
  },
  beforeCreate() {
    window.addEventListener("click", (e) => {
      this.clicked = this.clicked + 1;
      if (!this.$el.contains(e.target) && this.clicked > 1) {
        this.$emit("close-filter", false);
      }
    });
  },
  mounted() {
    if (this.contactFilterBoard.length) {
      this.filterList = this.contactFilterBoard.reduce(
        (result, item, index) => ({
          ...result,
          [index]: item,
        }),
        {}
      );
    }
  },
  methods: {
    getAttributeByName(name) {
      const data = this.contactAttributeMap[name];
      if (!data) return { type: "", value: [] };
      const { type = ATTRIBUTE_TYPE.TEXT_BOX, value = [] } = data;
      let options = [];
      if (type === ATTRIBUTE_TYPE.DROPDOWN) {
        options = optionDropdownAttribute(value);
      }
      if (type === ATTRIBUTE_TYPE.CHECKBOX) {
        options = optionCheckbox();
      }
      return {
        type,
        options,
      };
    },
    getComparisonOptionsByKey(key) {
      const { variable } = this.filterList[key];
      if (!variable) return this.getComparisonOptions();
      const condition = haveContainComparisonKey.includes(variable);
      if (!condition) {
        this.filterList[key].comparison = OUT_REACH_FILTER_COMPARISON.IS;
      }
      return this.getComparisonOptions(condition);
    },
    addNewComparison() {
      const newFilter = getDefaultFilterOutReach(this.filterList);
      this.filterList = {
        ...this.filterList,
        ...newFilter,
      };
    },
    onResetFilterList(isEmit) {
      this.filterList = {
        1: {
          variable: undefined,
          comparison: OUT_REACH_FILTER_COMPARISON.IS,
          value: "",
          valueArray: [],
        },
      };
      this.$store.commit("SET_CONTACT_FILTER_BOARD", []);
      isEmit && this.$emit("apply-filter", []);
    },
    onApplyFilter() {
      const values = Object.values(this.filterList).filter((item) => item.variable);
      this.$store.commit("SET_CONTACT_FILTER_BOARD", values);
      if (values.length) {
        this.$emit("apply-filter", Object.values(this.filterList));
      }
      this.$emit("close-filter", false);
    },
    getOptionByVariable(variable) {
      if (variable === "tags") return this.contactsTags.data;
      if (variable === "stage") return this.contactsStages.data;
      if (variable === "channel") return optionChannelFilter();
      return "";
    },
  },
};
</script>

<style lang="scss">
.filter-contacts {
  position: absolute;
  z-index: 999;
  width: 630px;
  height: 300px;
  background: white;
  top: 120%;
  left: 0;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
  border-radius: 4px;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &-container {
    &-filters {
      padding: 5px;
      display: flex;
      justify-content: space-between;
      &-container {
        padding: 5px;
        width: 100%;
        overflow-y: scroll;
        height: 224px;
        &-item {
          display: flex;
          > div {
            padding: 5px;
            width: 100%;

            .el-input__inner {
              border-color: black !important;
              padding-left: 10px;
              &::placeholder {
                color: black;
                opacity: 1; /* Firefox */
              }
            }
          }
        }
      }

      &-btn-add {
        > img {
          margin: 15px 5px 0 0;
          height: fit-content;
          cursor: pointer;
        }
      }
    }
    &-button {
      text-align: end;
      padding-right: 20px;
      .reset {
        border-color: black !important;
        color: black !important;
        &:hover {
          background: rgba(50, 50, 50, 0.121) !important;
        }
      }
      .apply {
        border-color: #1d57d8 !important;
        color: #1d57d8 !important;
      }
      &:first-child {
        font-weight: 500;
      }
    }
  }
  .popper__arrow {
    display: none !important;
  }
  // &-click-outside {
  //   width: 100vw;
  //   height: 100vh;
  //   background: "blue";
  // }
}
</style>
