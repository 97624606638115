<template>
  <div>
    <!-- Upload button accept JSON file only -->
    <el-upload
      action="#"
      class="upload-demo"
      :on-change="handleFileChange"
      :on-remove="handleRemoveFile"
      :auto-upload="false"
      :file-list="fileList"
      :limit="1"
      accept=".json"
    >
      <div class="upload-wrapper">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text" style="margin-bottom: 0">
          Drop file here or <em>click to upload</em> <br />
          File with a size less than 25mb
        </div>
      </div>
    </el-upload>

    <!-- Upload button -->
    <div style="text-align: right">
      <el-button
        v-if="fileList.length > 0"
        :loading="isUploading"
        :disabled="isUploading"
        type="primary"
        @click="handleUploadFile"
        style="margin: 10px 0"
      >
        Upload
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedPlugin: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fileList: [],
      isUploading: false,
    };
  },
  methods: {
    handleFileChange(file, fileList) {
      this.fileList = fileList;
      if (fileList.length > 0) {
        this.selectedPlugin.config.fileUrl = fileList[0].url;
        console.log("Fileurl", fileList[0].url);
      }
    },
    handleRemoveFile(file, fileList) {
      this.fileList = fileList;
      this.selectedPlugin.config.fileUrl = "";
    },
    async handleUploadFile() {
      try {
        this.isUploading = true;
        this.virusScan();

        if (this.fileList.length > 0) {
          const file = this.fileList[0];
          if (file.raw.type !== "application/json") {
            this.$message.error("Please upload a JSON file");
            return;
          }
        }

        const brain = this.$store.state.brain;
        const resp = await this.$store.dispatch("GENAI_UPLOAD_PLUGIN", {
          files: this.fileList,
          brain,
        });
        console.log(resp);
        if (resp.success) {
          this.$emit("updateFileUrl", _.first(resp.data).url);
          this.$nextTick(() => {
            this.$message.success("File uploaded successfully");
          });
        } else {
          this.$message.error("Failed to upload file");
        }
        this.isUploading = false;
      } catch (error) {
        this.$message.error("Failed to upload file");
        this.isUploading = false;
      }
    },
    async virusScan() {
      const formData = new FormData();
      const file = this.fileList[0].raw;
      formData.append("file", file);
      this.$rest("post", "miniapp_virus_scan", formData)
        .then((result) => {
          // this one to handle error coming from network level that returned 200
          const unexpectedResult = result.is_infected === undefined && result.viruses === undefined;
          const isSafe = !result.is_infected;
          const virusDetected = !_.isEmpty(result.viruses);
          if (unexpectedResult || !isSafe || virusDetected) {
            const message =
              !isSafe || virusDetected
                ? `File ${file.name} contain suspicious malware, please try with other file.`
                : "Failed to process virus scan. Please try again or contact support.";

            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message,
            });
            this.fileList = this.fileList.filter((item) => item.name !== file.name);
          }
        })
        .catch((error) => {
          const defaultMessage = "An error occurred while processing the file.";
          const errorData = _.get(error, "response.data", {});

          let errorMessage = typeof errorData === "string" ? errorData : errorData.message;
          if (errorMessage) {
            const errors = errorData.errors;
            if (Array.isArray(errors)) {
              const details = errors.join(".\n");
              errorMessage += `.\nDetails:\n${details}`;
            }
          }

          this.$notify({
            type: "error",
            title: "Error",
            position: "bottom-right",
            message: errorMessage || defaultMessage,
          });
          this.fileList = this.fileList.filter((item) => item.name !== file.name);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
